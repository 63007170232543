import React, { useState, useEffect, useRef } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import addButton from "./assets/addButton.png";
import subStractButton from "./assets/substractButton.png";
import axios from "axios";
import "./App.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: "#edf8fd",
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: "#edf8fd",
  },
  cardMedia: {
    width: "10vh",
    height: "10vh",
    marginLeft: "2vh",
    objectFit: "contain",
  },
  cardContentProduct: {
    flex: 3,
    marginLeft: "1vh",
  },
  cardProductTitle: {
    fontFamily: "Lato-Black",
    fontSize: "16px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.4,
    color: "#4c4c4c",
  },
  cardContentStockStatus: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardContentStockStatusTextNotInStock: {
    fontFamily: "Lato-Black",
    fontSize: "14px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.2,
    color: "#dd003f",
  },
  cardContentStockStatusTextInStock: {
    fontFamily: "Lato-Black",
    fontSize: "14px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.2,
    color: "#7ed321",
  },
  cardContentStockStatusTextNoData: {
    fontFamily: "Lato-Black",
    fontSize: "14px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.2,
    color: "#808d9f",
  },
  cardContentStockStatusTextNotInStockExpand: {
    fontFamily: "Lato-Black",
    fontSize: "14px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.2,
    color: "#dd003f",
  },
  cardContentStockStatusTextInStockExpand: {
    fontFamily: "Lato-Black",
    fontSize: "14px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.2,
    color: "#7ed321",
  },
  cardContentStockStatusTextNoDataExpand: {
    fontFamily: "Lato-Black",
    fontSize: "14px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.2,
    color: "#808d9f",
  },
  cardContentPrice: {
    flex: 2,
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  cardContentPriceText: {
    paddingLeft: "1vw",
    fontFamily: "Lato-Black",
    fontSize: "22px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.69,
    textAlign: "right",
    color: "#4c4c4c",
  },
  cardContentAction: {
    alignItems: "flex-end",
    flex: 2,
    display: "flex",
    flexDirection: "column",
  },
  cardActionButton: {
    borderRadius: "13.5px",
    backgroundColor: "#dd003f",
    shadowColor: "rgba(221, 0, 63, 0.11)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 7,
    shadowOpacity: 1,
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "14px",
    paddingBottom: "14px",
    "&:hover": {
      background: "#dd003f",
    },
    marginTop: "7px",
    minWidth: "140px",
  },
  cardActionButtonReserve: {
    borderRadius: "13.5px",
    backgroundColor: "white",
    shadowColor: "rgba(221, 0, 63, 0.11)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 7,
    shadowOpacity: 1,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "11px",
    paddingBottom: "11px",
    "&:hover": {
      background: "white",
    },
    borderColor: "#dd003f",
    borderWidth: "2px",
    borderStyle: "solid",
    marginBottom: "7px",
    minWidth: "140px",
    maxWidth: "180px",
  },
  cardActionButtonShop: {
    borderRadius: "13.5px",
    backgroundColor: "#dd003f",
    shadowColor: "rgba(221, 0, 63, 0.11)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 7,
    shadowOpacity: 1,
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "14px",
    paddingBottom: "14px",
    "&:hover": {
      background: "#dd003f",
    },
  },
  cardActionButtonText: {
    fontFamily: "Lato-Black",
    fontSize: "11px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 1.54,
    textAlign: "center",
    color: "#ffffff",
  },
  cardActionButtonTextReserve: {
    fontFamily: "Lato-Black",
    fontSize: "11px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 1.54,
    textAlign: "center",
    color: "#dd003f",
  },

  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "10vh",
    width: "100%",
  },

  root: {
    boxShadow: "0 3px 12px 0 rgba(50, 104, 141, 0.08)",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  card: {
    display: "flex",
    width: "90vw",
    borderRadius: "12px",
    boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)",
    marginTop: "2%",
    zIndex: 2,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  cardCollapse: {
    display: "flex",
    width: "88vw",
    borderRadius: "16.8px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    backgroundColor: "#f6f7f8",
    boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)",
    zIndex: 1,
    flexDirection: "column",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.4)",
    },
  },
  cardCollapseContent: {
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.4)",
    },
  },
  profilePicture: {
    boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)",
    borderStyle: "solid",
    borderWidth: 6,
    borderColor: "#ffffff",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    objectFit: "contain",
    marginTop: "110px",
  },
  footerTextWishes: {
    fontFamily: "Lato-Bold",
    fontSize: "13px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#dd003f",
  },
  footerText: {
    fontFamily: "Lato-Bold",
    fontSize: "13px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#bdc7d4",
  },
}));

export default function WishList(id) {
  const classes = useStyles();

  const [expand, setExpand] = useState(false);
  const [expandIndex, setExpandIndex] = useState(false);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [collaboratorsData, setCollaboratorsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookieConsent, setCookieConsent] = useState(true);
  const [reserve, setReserve] = useState(false);
  const [removeReserve, setRemoveReserve] = useState(false);
  const [dateInput1, setDateInput1] = useState("");
  const [dateInput2, setDateInput2] = useState("");
  const [dateInput3, setDateInput3] = useState("");
  const [dateInput4, setDateInput4] = useState("");
  const [wrongCodeEntered, setWrongCodeEntered] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [quantityReserved, setQuantityReserved] = useState(0);
  const [quantitySelected, setQuantitySelected] = useState(1);
  const [initial, setInitial] = useState(true);

  const [shareID, setShareID] = useState(id.match.params.id);
  const [selectedWishID, setSelectedWishID] = useState("");

  const [dateFilledOut, setDateFilledOut] = useState(false);

  const [enterBirhDay, setEnterBirthday] = useState(false);

  const widthRemoveReservationButton = useRef(null);
  const [removeResevartionButtonWidth, setRemoveResevartionButtonWidth] =
    useState(0);

  const date1Ref = useRef(null);
  const date2Ref = useRef(null);
  const date3Ref = useRef(null);
  const date4Ref = useRef(null);

  const checkStatus = (stock_status: string) => {
    if (stock_status === "true") {
      return (
        <Typography className={classes.cardContentStockStatusTextInStock}>
          In stock
        </Typography>
      );
    } else if (stock_status === "false") {
      return (
        <Typography className={classes.cardContentStockStatusTextNotInStock}>
          Out of stock
        </Typography>
      );
    } else {
      return null;
    }
  };

  const checkStatusExpand = (stock_status: string) => {
    if (stock_status === "true") {
      return (
        <Typography className={classes.cardContentStockStatusTextInStockExpand}>
          In stock
        </Typography>
      );
    } else if (stock_status === "false") {
      return (
        <Typography
          className={classes.cardContentStockStatusTextNotInStockExpand}
        >
          Out of stock
        </Typography>
      );
    } else {
      return (
        <Typography className={classes.cardContentStockStatusTextNoDataExpand}>
          No data 😕
        </Typography>
      );
    }
  };
  useEffect(() => {
    getWishList();
  }, []);

  const incrementQuantity = () => {
    setQuantitySelected(quantitySelected + 1);
  };

  const decrementQuantity = () => {
    setQuantitySelected(quantitySelected - 1);
  };

  useEffect(() => {
    if (
      dateInput1.length !== 0 &&
      dateInput2.length !== 0 &&
      dateInput3.length !== 0 &&
      dateInput4.length !== 0
    ) {
      setEnterBirthday(false);
      setDateFilledOut(true);
    } else {
      setDateFilledOut(false);
    }
  }, [dateInput1, dateInput2, dateInput3, dateInput4]);

  useEffect(() => {
    if (reserve === false || removeReserve) {
      setDateInput1("");
      setDateInput2("");
      setDateInput3("");
      setDateInput4("");
      setWrongCodeEntered(false);
      setEnterBirthday(false);
    }
  }, [reserve, removeReserve]);

  const reserveWish = async (shareID, wishID, status) => {
    const code = dateInput1 + dateInput2 + dateInput3 + dateInput4;
    const reserveInformation = {
      shareID,
      wishID,
      status,
      code,
      quantity: quantitySelected,
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + "getWebList/reserve",
        reserveInformation,
      )
      .then((response) => {
        if (response.data.code === 200) {
          setQuantitySelected(1);
          setQuantityReserved(0);
          setReserve(false);
          setExpand(false);
          getWishList();
        }
      });
  };

  const removeReserveWish = async (shareID, wishID, status) => {
    const code = dateInput1 + dateInput2 + dateInput3 + dateInput4;
    const reserveInformation = { shareID, wishID, code };
    axios
      .post(
        process.env.REACT_APP_API_URL + "getWebList/reserve/remove",
        reserveInformation,
      )
      .then((response) => {
        if (response.data.code === 200) {
          getWishList();
          setRemoveReserve(false);
        } else if (response.data.code === 500) {
          setWrongCodeEntered(true);
        }
      });
  };

  const getWishList = async () => {
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "getWebList/" + id.match.params.id)
        .then((res) => {
          res.data.wishData.wishes.sort((a, b) => {
            // nulls sort after anything else
            if (
              a.wishID.shops[0].newPrice === 0 ||
              a.wishID.shops[0].newPrice === null ||
              a.wishID.shops[0].newPrice === undefined
            ) {
              return 1;
            }
            if (
              b.wishID.shops[0].newPrice === 0 ||
              b.wishID.shops[0].newPrice === null ||
              b.wishID.shops[0].newPrice === undefined
            ) {
              return -1;
            }
            return a < b ? -1 : 1;
          });
          setData(res.data.wishData);
          if (localStorage.getItem("cookiesAccepted") === "true") {
            setCookieConsent(false);
          } else {
            setCookieConsent(true);
          }
          setUserData(res.data.personInformation);
          setCollaboratorsData(res.data.collaborators);
          setCollaboratorsData((collaboratorsData) => [
            ...collaboratorsData,
            res.data.personInformation,
          ]);
        })
        .finally(() => setLoading(false))
        .catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  };

  const renderCorrectBackgroundColor = (currBackgroundColor) => {
    if (currBackgroundColor === "white") {
      return "#f6f7f8";
    } else {
      return "white";
    }
  };

  const NumberList = (classes) => {
    return data.wishes.map((el, index) => {
      let backgroundcolor = el.wishID.shops.length % 2 ? "white" : "#f6f7f8";

      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i",
      ); // fragment locator

      const firstShopInStock = el.wishID.shops.filter(
        (shop) => shop.in_stock === "true",
      )[0];

      let domain;
      try {
        domain =
          el.wishID.shops[0].originalLink === null ||
          pattern.test(el.wishID.shops[0].originalLink) === false
            ? null
            : new URL(el.wishID.shops[0].originalLink);
      } catch (error) {
        domain = null;
      }

      let itemsReservedNumber = 0;
      let allReserved = false;
      let someReserved = false;

      if (el.reserved?.status !== undefined && el.reserved?.status === true) {
        allReserved = true;
      } else {
        el.reservedNew?.map(async (it) => {
          if (it.quantity !== undefined) {
            itemsReservedNumber = itemsReservedNumber + it.quantity;
          }
          if (
            (el.quantity === 1 && it.user !== undefined) ||
            (el.quantity === 1 && it.code !== null) ||
            el.quantity === itemsReservedNumber
          ) {
            allReserved = true;
          } else if (
            (it.user !== undefined && it.quantity === itemsReservedNumber) ||
            (it.code !== null && it.quantity === itemsReservedNumber)
          ) {
            someReserved = true;
          }
        });
      }

      const renderExpand = () => {
        if (expand && expandIndex === index) {
          const shops =
            firstShopInStock === undefined ||
            el.wishID.shops[0].in_stock !== "false"
              ? el.wishID.shops.slice(1)
              : el.wishID.shops;

          return (
            <Card
              onClick={() => {
                setExpand(false);
                setExpandIndex(index);
              }}
              className={classes.cardCollapse}
            >
              {shops.map((shop, index) => {
                backgroundcolor = renderCorrectBackgroundColor(backgroundcolor);

                return (
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      backgroundColor: backgroundcolor,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <CardMedia
                        style={{
                          paddingLeft: "2.5vmin",
                          height: "50px",
                          width: "120px",
                          objectFit: "contain",
                        }}
                        component="img"
                        image={
                          "https://images.cupio.app/images/logos/" +
                          shop.advertiserId +
                          ".png"
                        }
                        title="Image title"
                      />
                    </div>
                    <div>{checkStatusExpand(shop.in_stock)}</div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Lato-Black",
                            fontSize: "14px",
                            fontWeight: 900,
                            fontStyle: "normal",
                            letterSpacing: 0.69,
                            color: "#4c4c4c",
                            textAlign: "center",
                          }}
                          variant="subtitle2"
                          color="inherit"
                        >
                          {shop.newPrice.toLocaleString("da-DK", {
                            maximumFractionDigits: 0,
                          })}{" "}
                          {shop.visualCurrency}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 0.95,
                          alignItems: "flex-end",
                          justifyContent: "space-evenly",
                          paddingRight: "1vw",
                        }}
                      >
                        <Button
                          onClick={(event) =>
                            window.open(shop.refproductLink, "_blank")
                          }
                          className={classes.cardActionButtonShop}
                        >
                          <Typography className={classes.cardActionButtonText}>
                            GO TO STORE
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                );
              })}
              <CardContent
                className={classes.cardCollapseContent}
                style={{
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  flexDirection: "column",
                  backgroundColor: "#f6f7f8",
                  cursor: "pointer",
                }}
              >
                <img
                  src={"https://cupio.nu/assets/images/6-a.svg"}
                  style={{ height: 18, width: 13, objectFit: "contain" }}
                  alt=""
                ></img>
                <Typography
                  style={{
                    fontFamily: "Lato-Black",
                    paddingBottom: "5px",
                    fontSize: "11.2px",
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 1.4,
                    color: "#404040",
                  }}
                >
                  COMPARE RETAILERS
                </Typography>
              </CardContent>
            </Card>
          );
        } else {
          return (
            <Card
              onClick={() => {
                if (allReserved) {
                } else {
                  setExpand(true);
                  setExpandIndex(index);
                }
              }}
              style={{ opacity: allReserved ? 0.45 : 1, cursor: "pointer" }}
              className={classes.cardCollapse}
            >
              <CardContent
                style={{
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Lato-Black",
                    paddingTop: "5px",
                    fontSize: "11.2px",
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 1.4,
                    color: "#404040",
                  }}
                >
                  COMPARE RETAILERS
                </Typography>
                <img
                  src={"https://cupio.nu/assets/images/6-a.svg"}
                  style={{
                    height: 18,
                    width: 13,
                    objectFit: "contain",
                    transform: "rotate(180deg)",
                  }}
                  alt=""
                ></img>
              </CardContent>
            </Card>
          );
        }
      };

      const renderGoToStore = () => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1.5,
              alignItems: "flex-end",
            }}
          >
            {allReserved ? null : (
              <Button
                onClick={() => {
                  setReserve(true);
                  setQuantity(el.quantity);
                  setQuantityReserved(itemsReservedNumber);
                  setSelectedWishID(el.wishID._id);
                }}
                className={classes.cardActionButtonReserve}
              >
                <Typography className={classes.cardActionButtonTextReserve}>
                  RESERVE
                </Typography>
              </Button>
            )}
            {allReserved ? (
              <div
                style={{
                  minWidth: "140px",
                  maxWidth: "200px",
                }}
              ></div>
            ) : (
              <div>
                {el.wishID.shops[0].refproductLink === null ? (
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "140px",
                    }}
                  >
                    <Typography
                      style={{
                        paddingTop: "10px",
                        fontFamily: "Lato-Regular",
                        fontSize: 14,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        textAlign: "center",
                        color: "#97a1ae",
                      }}
                    >
                      Added manually
                    </Typography>
                    <Typography
                      style={{
                        marginTop: "-5px",
                        fontFamily: "Lato-Regular",
                        fontSize: 14,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        textAlign: "center",
                        color: "#97a1ae",
                      }}
                    >
                      without a link
                    </Typography>
                  </div>
                ) : (
                  <Button
                    onClick={(event) =>
                      window.open(
                        firstShopInStock === undefined
                          ? el.wishID.shops[0].refproductLink
                          : firstShopInStock.refproductLink,
                        "_blank",
                      )
                    }
                    className={classes.cardActionButton}
                  >
                    <Typography className={classes.cardActionButtonText}>
                      GO TO STORE
                    </Typography>
                  </Button>
                )}
              </div>
            )}
          </div>
        );
      };

      return (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card className={classes.card}>
            {allReserved ? (
              <div
                ref={widthRemoveReservationButton}
                style={{
                  position: "absolute",
                  zIndex: 4,
                  display: "flex",
                  paddingBottom: "80px",
                  paddingRight: "10px",
                }}
              >
                <Button
                  style={{ width: "200px" }}
                  onClick={() => {
                    setRemoveReserve(true);
                    setSelectedWishID(el.wishID._id);
                  }}
                  className={classes.cardActionButtonReserve}
                >
                  <Typography className={classes.cardActionButtonTextReserve}>
                    REMOVE RESERVATION
                  </Typography>
                </Button>
              </div>
            ) : null}
            {allReserved ? (
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#f0f1f3",
                  borderBottomRightRadius: "12px",
                  borderTopLeftRadius: "14px",
                  borderBottomLeftRadius: "0px",
                  zIndex: 3,
                  display: "flex",
                  width: "190px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "55px",
                }}
              >
                <div style={{ paddingBottom: "5px" }}>
                  <CardMedia
                    style={{
                      objectFit: "contain",
                      height: "30px",
                      width: "30px",
                      paddingRight: "15px",
                    }}
                    component="img"
                    image={"https://cupio.nu/assets/images/giftPackage@3x.png"}
                    title="Image title"
                  />
                </div>
                <Typography
                  style={{
                    paddingLeft: "15px",
                    fontFamily: "Lato-Bold",
                    fontSize: 16,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0.1,
                    textAlign: "center",
                    color: "#808d9f",
                  }}
                >
                  Reserved
                </Typography>
              </div>
            ) : null}
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                padding: "10px",
                opacity: allReserved ? 0.25 : 1,
              }}
            >
              <div
                style={{
                  flex: 1,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "14px",
                }}
              >
                <img
                  style={{
                    width: "130px",
                    height: "130px",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                  alt=""
                  src={el.wishID.thumbNail}
                />
                <div
                  style={{ flexDirection: "column", flex: 2, display: "flex" }}
                >
                  {el.quantity !== undefined && el.quantity >= 2 ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      {" "}
                      <Typography
                        style={{
                          paddingRight: "20px",
                          paddingLeft: "1.7vw",
                          fontFamily: "lato-regular",
                          fontSize: 16,
                          fontWeight: "bold",
                          fontStyle: "normal",
                          letterSpacing: 0.2,
                          color: "#bdc7d4",
                          marginBottom: 5,
                        }}
                      >
                        {el.quantity} pieces /{" "}
                        <Typography
                          display="inline"
                          style={{
                            fontFamily: "lato-bold",
                            fontSize: 16,
                            fontWeight: "bold",
                            fontStyle: "normal",
                            letterSpacing: 0.2,
                            color: "#bdc7d4",
                          }}
                        >
                          {el.quantity - itemsReservedNumber} left
                        </Typography>
                      </Typography>
                    </div>
                  ) : null}

                  <Typography
                    style={{
                      paddingRight: "20px",
                      paddingLeft: "1.7vw",
                      fontFamily: "Lato-Black",
                      fontSize: "14px",
                      fontWeight: 900,
                      fontStyle: "normal",
                      letterSpacing: 0.1,
                      color: "#4c4c4c",
                    }}
                  >
                    {el.wishID.title}
                  </Typography>
                  <Typography
                    style={{
                      marginTop: "5px",
                      paddingRight: "20px",
                      paddingLeft: "1.7vw",
                      fontFamily: "Lato-Regular",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      color: "#4c4c4c",
                    }}
                  >
                    {el.description}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {el.wishID.manualAdded ? (
                  <div style={{ width: "10vw" }}>
                    {el.wishID.shops[0].originalLink === null ? null : (
                      <Typography
                        style={{
                          textAlign: "center",
                          fontFamily: "Lato-Regular",
                          fontSize: 16,
                          fontWeight: "normal",
                          fontStyle: "normal",
                          letterSpacing: 0.1,
                          color: "#808d9f",
                        }}
                      >
                        {domain === null ? null : domain.hostname}
                      </Typography>
                    )}
                  </div>
                ) : (
                  <CardMedia
                    style={{
                      objectFit: "contain",
                      height: "35px",
                      width: "10vw",
                    }}
                    component="img"
                    image={
                      firstShopInStock === undefined
                        ? "https://images.cupio.app/images/logos/" +
                          el.wishID.shops[0].advertiserId +
                          ".png"
                        : "https://images.cupio.app/images/logos/" +
                          firstShopInStock.advertiserId +
                          ".png"
                    }
                    title="Image title"
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1.7,
                    justifyContent: "center",
                  }}
                >
                  {firstShopInStock === undefined
                    ? checkStatus(el.wishID.shops[0].in_stock)
                    : checkStatus(firstShopInStock.in_stock)}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 0.8,
                    justifyContent: "center",
                  }}
                >
                  {firstShopInStock === undefined ? (
                    <div
                      style={{
                        display: "flex",
                        flex: 0.8,
                        justifyContent: "center",
                      }}
                    >
                      {el.wishID.shops[0].newPrice !== null &&
                      el.wishID.shops[0].newPrice !== 0 ? (
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            fontFamily: "Lato-Black",
                            fontSize: "18px",
                            fontWeight: 900,
                            fontStyle: "normal",
                            letterSpacing: 0.69,
                            color: "#4c4c4c",
                          }}
                        >
                          {el.wishID.shops[0].newPrice.toLocaleString("da-DK", {
                            maximumFractionDigits: 0,
                          })}{" "}
                          kr.
                        </Typography>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flex: 0.8,
                        justifyContent: "center",
                      }}
                    >
                      {firstShopInStock.newPrice !== null &&
                      firstShopInStock.newPrice !== 0 ? (
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            fontFamily: "Lato-Black",
                            fontSize: "18px",
                            fontWeight: 900,
                            fontStyle: "normal",
                            letterSpacing: 0.69,
                            color: "#4c4c4c",
                          }}
                        >
                          {firstShopInStock.newPrice.toLocaleString("da-DK", {
                            maximumFractionDigits: 0,
                          })}{" "}
                          kr.
                        </Typography>
                      ) : null}
                    </div>
                  )}
                </div>
                {renderGoToStore()}
              </div>
            </CardContent>
          </Card>
          {el.wishID.shops.length >= 2 ? renderExpand() : null}
        </div>
      );
    });
  };

  const handleDateInputRef = (value, index, keypressed) => {
    //Ensure only numbers are entered.
    var pattern = /^\d+$/;

    if (index === 1 && pattern.test(value)) {
      if (value.length <= 1 && value <= 3) {
        setDateInput1(value);
        if (value.length !== 0) {
          date2Ref.current.focus();
        }
      }
    } else if (index === 2 && pattern.test(value)) {
      if (value.length <= 1) {
        setDateInput2(value);
        if (value.length !== 0) {
          date3Ref.current.focus();
        }
      }
    } else if (index === 3 && value <= 1 && pattern.test(value)) {
      setDateInput3(value);
      if (value.length !== 0) {
        date4Ref.current.focus();
      }
    } else if (index === 4 && pattern.test(value)) {
      if (value.length <= 1) {
        setDateInput4(value);
      }
    }
  };

  return (
    <React.Fragment>
      {loading ? null : (
        <div>
          <Helmet>
            <meta name="title" content={data.wishListName.toUpperCase()}></meta>
            <meta
              name="description"
              content={
                userData.name.split(" ")[0] +
                " shared the list " +
                data.wishListName.toUpperCase() +
                " with you."
              }
            ></meta>
            <meta
              name="og:image"
              content={
                "https://assets-global.website-files.com/63f3764fa511aa60d0ad9849/642bd14d5203807da729b0e0_Cupio_logo.jpg"
              }
            ></meta>
            <meta property="og:type" content={"wishlist"} />
            <meta
              property="og:title"
              content={data.wishListName.toUpperCase()}
            />
            <meta
              property="og:description"
              content={
                userData.name.split(" ")[0] +
                " shared the list " +
                data.wishListName.toUpperCase() +
                " with you."
              }
            />
          </Helmet>
          {cookieConsent ? (
            <div
              style={{
                position: "fixed",
                height: "100vh",
                width: "100vw",
                display: "flex",
                backgroundColor: "rgba(52, 52, 52, 0.8)",
                zIndex: 3,
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  padding: "40px",
                  maxWidth: "400px",
                  marginRight: "50px",
                  marginBottom: "25px",
                  display: "flex",
                  backgroundColor: "#edf8fd",
                  flexDirection: "column",
                  borderRadius: 28,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Lato-Black",
                    fontSize: 19,
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                >
                  Have a cookie 🍪
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Lato-Regular",
                    marginTop: "20px",
                    fontSize: 16,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#808d9f",
                  }}
                >
                  We respect your right to privacy, we therefore kindly ask for
                  your permission to use cookies to personalize content and to
                  analyze web traffic on our site.
                </Typography>
                <Typography
                  onClick={(event) =>
                    (window.location.href = "https://cupio.nu/cookie-policy/")
                  }
                  style={{
                    fontFamily: "Lato-Regular",
                    marginTop: "20px",
                    fontSize: 16,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    textDecorationLine: "underline",
                    letterSpacing: 0,
                    color: "#808d9f",
                  }}
                >
                  Read our cookie policy
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  <Button
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "18px",
                      paddingBottom: "18px",
                      backgroundColor: "white",
                      borderRadius: "16px",
                      boxShadow:
                        "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                    }}
                    onClick={() => {
                      setCookieConsent(false);
                      localStorage.setItem("cookiesAccepted", "true");
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Black",
                        fontSize: 13,
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 2,
                        textAlign: "center",
                        color: "#808d9f",
                      }}
                    >
                      FUNCTIONAL ONLY
                    </Typography>
                  </Button>{" "}
                  <Button
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "18px",
                      paddingBottom: "18px",
                      backgroundColor: "#dd003f",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      setCookieConsent(false);
                      localStorage.setItem("cookiesAccepted", "true");
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Black",
                        fontSize: 13,
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 2,
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      ACCEPT COOKIES
                    </Typography>
                  </Button>{" "}
                </div>{" "}
              </div>
            </div>
          ) : null}
          {reserve ? (
            <div
              style={{
                position: "fixed",
                height: "100vh",
                width: "100vw",
                display: "flex",
                backgroundColor: "rgba(52, 52, 52, 0.8)",
                zIndex: 5,
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25vh",
                  position: "absolute",
                  flexGrow: 1,
                  padding: "40px",
                  maxWidth: "400px",
                  marginRight: "50px",
                  marginBottom: "25px",
                  display: "flex",
                  backgroundColor: "#edf8fd",
                  flexDirection: "column",
                  borderRadius: 28,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "lato-black",
                    fontSize: 14,
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 2.4,
                    textAlign: "center",
                    color: "#404040",
                  }}
                >
                  RESERVE WISH
                </Typography>
                {quantity >= 2 ? (
                  <div>
                    {" "}
                    <Typography
                      style={{
                        marginTop: 20,
                        fontFamily: "lato-black",
                        fontSize: 19,
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 0,
                        textAlign: "center",
                        color: "#4c4c4c",
                        marginBottom: "20px",
                      }}
                    >
                      1. Select quantity
                    </Typography>
                    <Button
                      style={{
                        height: "49px",
                        width: "47px",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        marginRight: "10px",
                        marginBottom: "6px",
                        boxShadow:
                          "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                      }}
                      onClick={() => {
                        if (quantitySelected >= 2) {
                          setInitial(false);
                          decrementQuantity();
                        }
                      }}
                    >
                      {" "}
                      <img
                        style={{
                          width: "12px",
                          height: "3px",
                        }}
                        alt=""
                        src={subStractButton}
                      />
                    </Button>
                    <input
                      disabled={true}
                      type={"number"}
                      value={quantitySelected}
                      style={{
                        marginTop: "10px",
                        textAlign: "center",
                        height: "47px",
                        width: "64px",
                        borderRadius: "8px",
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                      }}
                    />
                    <Button
                      style={{
                        height: "49px",
                        width: "47px",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        marginLeft: "10px",
                        marginBottom: "6px",
                        boxShadow:
                          "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                      }}
                      onClick={() => {
                        if (quantitySelected < quantity - quantityReserved) {
                          setInitial(false);
                          incrementQuantity();
                        }
                      }}
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                        }}
                        alt=""
                        src={addButton}
                      />
                    </Button>
                    <Typography
                      style={{
                        paddingRight: "20px",
                        paddingLeft: "1.7vw",
                        fontFamily: "lato-regular",
                        fontSize: 16,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        textAlign: "center",
                        letterSpacing: 0.2,
                        color: "#bdc7d4",
                        marginBottom: 5,
                        marginTop: 10,
                      }}
                    >
                      {quantity} pieces /{" "}
                      <Typography
                        display="inline"
                        style={{
                          fontFamily: "lato-bold",
                          fontSize: 16,
                          fontWeight: "bold",
                          fontStyle: "normal",
                          letterSpacing: 0.2,
                          textAlign: "center",
                          color: "#bdc7d4",
                        }}
                      >
                        {initial
                          ? quantity - (quantitySelected + quantityReserved - 1)
                          : quantity -
                            (quantitySelected + quantityReserved)}{" "}
                        left
                      </Typography>
                    </Typography>
                  </div>
                ) : null}
                <Typography
                  style={{
                    fontFamily: "lato-black",
                    fontSize: 19,
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#4c4c4c",
                    marginTop: 20,
                  }}
                >
                  {quantity >= 2 ? "2." : null} Enter your birthday
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Lato-Regular",
                    marginTop: 20,
                    fontSize: 16,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#808d9f",
                    textAlign: "center",
                  }}
                >
                  Please enter your birthday as your personal reservation code.
                  You will need this code if you later want to remove your
                  reservation.
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "4vh",
                  }}
                >
                  <div style={{ marginRight: "20px" }}>
                    <input
                      type={"number"}
                      onKeyDown={(key) => {
                        if (key.code === "Backspace") {
                          setDateInput1("");
                        }
                        if (key.code === "ArrowRight") {
                          date2Ref.current.focus();
                        }
                      }}
                      value={dateInput1}
                      onChange={(text) =>
                        handleDateInputRef(text.target.value, 1, null)
                      }
                      ref={date1Ref}
                      placeholder={"D"}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        height: "53px",
                        width: "53px",
                        borderRadius: 8,
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                      }}
                    />
                    <input
                      type={"text"}
                      value={dateInput2}
                      onKeyDown={(key) => {
                        if (key.code === "Backspace") {
                          setDateInput2("");
                        }
                        if (key.code === "ArrowLeft") {
                          date1Ref.current.focus();
                        }
                        if (key.code === "ArrowRight") {
                          date3Ref.current.focus();
                        }
                        if (
                          key.code === "Backspace" &&
                          dateInput2.length === 0
                        ) {
                          date1Ref.current.focus();
                        }
                      }}
                      onChange={(text) =>
                        handleDateInputRef(text.target.value, 2, null)
                      }
                      ref={date2Ref}
                      placeholder={"D"}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        height: "53px",
                        width: "53px",
                        borderRadius: 8,
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <Typography
                    style={{
                      fontFamily: "Lato-Bold",
                      fontSize: 19,
                      fontWeight: "bold",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "center",
                      color: "#808d9f",
                    }}
                  >
                    /
                  </Typography>
                  <div style={{ marginLeft: "20px" }}>
                    <input
                      type={"number"}
                      value={dateInput3}
                      onKeyDown={(key) => {
                        if (key.code === "Backspace") {
                          setDateInput3("");
                        }
                        if (key.code === "ArrowLeft") {
                          date2Ref.current.focus();
                        }
                        if (key.code === "ArrowRight") {
                          date4Ref.current.focus();
                        }
                        if (
                          key.code === "Backspace" &&
                          dateInput3.length === 0
                        ) {
                          date2Ref.current.focus();
                        }
                      }}
                      onChange={(text) =>
                        handleDateInputRef(text.target.value, 3, null)
                      }
                      ref={date3Ref}
                      placeholder={"M"}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        height: "53px",
                        width: "53px",
                        borderRadius: 8,
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                      }}
                    />
                    <input
                      type={"number"}
                      value={dateInput4}
                      onKeyDown={(key) => {
                        if (key.code === "Backspace") {
                          setDateInput4("");
                        }
                        if (key.code === "ArrowLeft") {
                          date3Ref.current.focus();
                        }
                        if (
                          key.code === "Backspace" &&
                          dateInput4.length === 0
                        ) {
                          date3Ref.current.focus();
                        }
                      }}
                      onChange={(text) =>
                        handleDateInputRef(text.target.value, 4, null)
                      }
                      ref={date4Ref}
                      placeholder={"M"}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        height: "53px",
                        width: "53px",
                        borderRadius: 8,
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        marginLeft: "10px",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                      }}
                    />
                  </div>
                </div>
                {enterBirhDay ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "15px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.18,
                        textAlign: "center",
                        color: "#dd003f",
                      }}
                    >
                      Please enter your correct birhday
                    </Typography>
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: enterBirhDay ? "2vh" : "4vh",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    style={{
                      paddingLeft: "60px",
                      paddingRight: "60px",
                      paddingTop: "18px",
                      paddingBottom: "18px",
                      backgroundColor: dateFilledOut ? "#dd003f" : "white",
                      borderRadius: "12px",
                      boxShadow:
                        "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                    }}
                    onClick={() => {
                      if (
                        dateInput1.length === 0 ||
                        dateInput2.length === 0 ||
                        dateInput3.length === 0 ||
                        dateInput4.length === 0
                      ) {
                        setEnterBirthday(true);
                      } else {
                        reserveWish(shareID, selectedWishID, true);
                      }
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Black",
                        fontSize: 11,
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 1.75,
                        textAlign: "center",
                        color: dateFilledOut ? "white" : "#cfcfcf",
                      }}
                    >
                      RESERVE WISH
                    </Typography>
                  </Button>{" "}
                  <Button
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "18px",
                      paddingBottom: "18px",
                      borderRadius: "16px",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      setReserve(false);
                      localStorage.setItem("cookiesAccepted", "true");
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Black",
                        fontSize: 11,
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 1.75,
                        textAlign: "center",
                        color: "#808d9f",
                      }}
                    >
                      CANCEL
                    </Typography>
                  </Button>{" "}
                </div>{" "}
              </div>
            </div>
          ) : null}
          {removeReserve ? (
            <div
              style={{
                position: "fixed",
                height: "100vh",
                width: "100vw",
                display: "flex",
                backgroundColor: "rgba(52, 52, 52, 0.8)",
                zIndex: 3,
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  marginTop: "25vh",
                  position: "absolute",
                  flexGrow: 1,
                  padding: "40px",
                  maxWidth: "400px",
                  marginRight: "50px",
                  marginBottom: "25px",
                  display: "flex",
                  backgroundColor: "#edf8fd",
                  flexDirection: "column",
                  borderRadius: 28,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Lato-Black",
                    fontSize: 21,
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                    textAlign: "center",
                  }}
                >
                  Enter your birthday
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Lato-Regular",
                    marginTop: "20px",
                    fontSize: 16,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#808d9f",
                    textAlign: "center",
                  }}
                >
                  To remove this reservation, please enter the same birthday
                  that was used to create it.
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "4vh",
                  }}
                >
                  <div style={{ marginRight: "20px" }}>
                    <input
                      type={"number"}
                      onKeyDown={(key) => {
                        if (key.code === "Backspace") {
                          setDateInput1("");
                        }
                        if (key.code === "ArrowRight") {
                          date2Ref.current.focus();
                        }
                      }}
                      value={dateInput1}
                      onChange={(text) =>
                        handleDateInputRef(text.target.value, 1, null)
                      }
                      ref={date1Ref}
                      placeholder={"D"}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        height: "53px",
                        width: "53px",
                        borderRadius: 8,
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                      }}
                    />
                    <input
                      type={"number"}
                      value={dateInput2}
                      onKeyDown={(key) => {
                        if (key.code === "Backspace") {
                          setDateInput2("");
                        }
                        if (key.code === "ArrowLeft") {
                          date1Ref.current.focus();
                        }
                        if (key.code === "ArrowRight") {
                          date3Ref.current.focus();
                        }
                        if (
                          key.code === "Backspace" &&
                          dateInput2.length === 0
                        ) {
                          date1Ref.current.focus();
                        }
                      }}
                      onChange={(text) =>
                        handleDateInputRef(text.target.value, 2, null)
                      }
                      ref={date2Ref}
                      placeholder={"D"}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        height: "53px",
                        width: "53px",
                        borderRadius: 8,
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <Typography
                    style={{
                      fontFamily: "Lato-Bold",
                      fontSize: 19,
                      fontWeight: "bold",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      textAlign: "center",
                      color: "#808d9f",
                    }}
                  >
                    /
                  </Typography>
                  <div style={{ marginLeft: "20px" }}>
                    <input
                      type={"number"}
                      value={dateInput3}
                      onKeyDown={(key) => {
                        if (key.code === "Backspace") {
                          setDateInput3("");
                        }
                        if (key.code === "ArrowLeft") {
                          date2Ref.current.focus();
                        }
                        if (key.code === "ArrowRight") {
                          date4Ref.current.focus();
                        }
                        if (
                          key.code === "Backspace" &&
                          dateInput3.length === 0
                        ) {
                          date2Ref.current.focus();
                        }
                      }}
                      onChange={(text) =>
                        handleDateInputRef(text.target.value, 3, null)
                      }
                      ref={date3Ref}
                      placeholder={"M"}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        height: "53px",
                        width: "53px",
                        borderRadius: 8,
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                      }}
                    />
                    <input
                      type={"number"}
                      value={dateInput4}
                      onKeyDown={(key) => {
                        if (key.code === "Backspace") {
                          setDateInput4("");
                        }
                        if (key.code === "ArrowLeft") {
                          date3Ref.current.focus();
                        }
                        if (
                          key.code === "Backspace" &&
                          dateInput4.length === 0
                        ) {
                          date3Ref.current.focus();
                        }
                      }}
                      onChange={(text) =>
                        handleDateInputRef(text.target.value, 4, null)
                      }
                      ref={date4Ref}
                      placeholder={"M"}
                      maxLength={1}
                      style={{
                        textAlign: "center",
                        height: "53px",
                        width: "53px",
                        borderRadius: 8,
                        backgroundColor: "#ffffff",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#e9e9e9",
                        marginLeft: "10px",
                        fontFamily: "Lato-Bold",
                        fontSize: 20,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        color: "#4c4c4c",
                      }}
                    />
                  </div>
                </div>
                {enterBirhDay ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "15px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.18,
                        textAlign: "center",
                        color: "#dd003f",
                      }}
                    >
                      Please enter your birhday
                    </Typography>
                  </div>
                ) : null}
                {wrongCodeEntered ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "15px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.18,
                        textAlign: "center",
                        color: "#dd003f",
                      }}
                    >
                      Please enter the correct code
                    </Typography>
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: enterBirhDay ? "2vh" : "4vh",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    style={{
                      paddingLeft: "60px",
                      paddingRight: "60px",
                      paddingTop: "18px",
                      paddingBottom: "18px",
                      backgroundColor: dateFilledOut ? "#dd003f" : "white",
                      borderRadius: "12px",
                      boxShadow:
                        "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                    }}
                    onClick={() => {
                      if (
                        dateInput1.length === 0 ||
                        dateInput2.length === 0 ||
                        dateInput3.length === 0 ||
                        dateInput4.length === 0
                      ) {
                        setEnterBirthday(true);
                      } else {
                        removeReserveWish(shareID, selectedWishID, true);
                      }
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Black",
                        fontSize: 11,
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 1.75,
                        textAlign: "center",
                        color: dateFilledOut ? "white" : "#cfcfcf",
                      }}
                    >
                      REMOVE RESERVATION
                    </Typography>
                  </Button>{" "}
                  <Button
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingTop: "18px",
                      paddingBottom: "18px",
                      borderRadius: "16px",
                      marginTop: "10px",
                    }}
                    onClick={() => {
                      setRemoveReserve(false);
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Lato-Black",
                        fontSize: 11,
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 1.75,
                        textAlign: "center",
                        color: "#808d9f",
                      }}
                    >
                      CANCEL
                    </Typography>
                  </Button>{" "}
                </div>{" "}
              </div>
            </div>
          ) : null}
          <div className={classes.root}>
            <AppBar
              style={{ boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)" }}
              position={cookieConsent ? "relative" : "fixed"}
              color="inherit"
            >
              <Toolbar>
                <img
                  src={"https://cupio.nu/assets/images/cupio-logo.svg"}
                  style={{ height: "40px", width: "90px" }}
                  alt="logo"
                />
                <Typography
                  style={{
                    textAlign: "center",
                    fontFamily: "Lato-Black",
                    fontSize: "16px",
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 2.93,
                    color: "#404040",
                  }}
                  className={classes.fabButton}
                >
                  {data.wishListName.toUpperCase()}
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            style={{ minHeight: "93vh", backgroundColor: "#edf8fd" }}
          >
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {collaboratorsData.map((collaborator, index) => {
                return (
                  <div style={{ marginLeft: index === 0 ? 0 : -35 }}>
                    {collaborator.onlinePicture === null ? (
                      <img
                        className={classes.profilePicture}
                        src={`data:image/jpeg;base64,${collaborator.offlinePicture}`}
                        alt="user"
                      ></img>
                    ) : (
                      <img
                        className={classes.profilePicture}
                        src={collaborator.onlinePicture}
                        alt="user"
                      ></img>
                    )}
                  </div>
                );
              })}

              {/* <Typography
                style={{
                  marginTop: "1vh",
                  fontFamily: "Lato-Bold",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0.28,
                  textAlign: "end",
                  color: "#4c4c4c",
                }}
              >&</Typography>
              <div style={{ flexDirection: "row", marginLeft: -25 }}>
                {userData.onlinePicture === null ? (
                  <img
                    className={classes.profilePicture}
                    src={`data:image/jpeg;base64,${userData.offlinePicture}`}
                    alt="user"
                  ></img>
                ) : (
                  <img
                    className={classes.profilePicture}
                    src={userData.onlinePicture}
                    alt="user"
                  ></img>
                )}
                <Typography
                  style={{
                    marginTop: "1vh",
                    fontFamily: "Lato-Bold",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0.28,
                    textAlign: "center",
                    color: "#4c4c4c",
                  }}
                >
                  & {userData.name.split(" ")[0]}
                </Typography>
              </div> */}
            </Grid>
            <Grid container spacing={0} direction="row" justifyContent="center">
              {collaboratorsData.map((collaborator, index) => {
                if (index === collaboratorsData?.length - 1) {
                  return (
                    <Typography
                      noWrap
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.28,
                        textAlign: "center",
                        color: "#4c4c4c",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {collaborator.name.split(" ")[0]}
                    </Typography>
                  );
                } else if (index === collaboratorsData?.length - 2) {
                  return (
                    <Typography
                      noWrap
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.28,
                        textAlign: "center",
                        color: "#4c4c4c",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {collaborator.name.split(" ")[0]}&nbsp; & &nbsp;
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      noWrap
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.28,
                        textAlign: "center",
                        color: "#4c4c4c",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {collaborator.name.split(" ")[0]}, &nbsp;
                    </Typography>
                  );
                }
              })}
            </Grid>
            {data.wishes.length === 1 ? (
              <Typography
                style={{
                  marginTop: "1vh",
                  color: "#97a1ae",
                  fontFamily: "Lato-Bold",
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0.46,
                  textAlign: "center",
                }}
              >
                {data.wishes.length} item{" "}
              </Typography>
            ) : (
              <Typography
                style={{
                  marginTop: "1vh",
                  color: "#97a1ae",
                  fontFamily: "Lato-Bold",
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0.46,
                  textAlign: "center",
                }}
              >
                {data.wishes.length} items{" "}
              </Typography>
            )}

            {NumberList(classes)}

            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5vh",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato-Bold",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "center",
                  color: "#808d9f",
                }}
              >
                Like what you see?
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato-Bold",
                  fontSize: "16px",
                  letterSpacing: 0,
                  fontWeight: "bold",
                  fontStyle: "normal",
                  textAlign: "center",
                  color: "#808d9f",
                }}
              >
                Create your own wish list with cupio 🥳
              </Typography>
            </div>
            <div style={{ marginTop: "4vh" }}>
              <img
                onClick={(event) =>
                  (window.location.href =
                    "https://apps.apple.com/dk/app/cupio/id1587188542?l=da")
                }
                style={{
                  width: "120px",
                  height: "45px",
                }}
                alt=""
                src={"https://cupio.nu/assets/images/AppStore.svg"}
              />
              <img
                style={{
                  marginLeft: "25px",
                  width: "120px",
                  height: "45px",
                }}
                onClick={(event) =>
                  (window.location.href =
                    "https://play.google.com/store/apps/details?id=com.cupio.cupio&fbclid=IwAR2WYsRsE7nPmbngxresCOTTBmzqscqBk-UFjwq1r1uJ4GuUQX4bm-VBL04&pli=1")
                }
                alt=""
                src={"https://cupio.nu/assets/images/GooglePlay.svg"}
              />
            </div>
            <div style={{ marginTop: "4vh", marginBottom: "4vh" }}>
              <Typography
                style={{
                  fontFamily: "Lato-Regular",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "center",
                  color: "#97a1ae",
                }}
              >
                Unfortunately, we cannot guarantee that all retailers are
                included in the comparison. Additionally, we are not responsible
                for any changes in price or stock status.
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato-Regular",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "center",
                  color: "#97a1ae",
                }}
              >
                We solely rank based on price and do not favor any retailers
                over others. Our links are affiliate links - but rest assured,
                it does not affect the price of your items!
              </Typography>
            </div>
          </Grid>
          <div
            style={{
              bottom: 0,
              backgroundColor: "white",
              width: "100%",
              justifyContent: "space-around",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              boxShadow: "0 -5px 20px 0 rgba(43, 74, 95, 0.08)",
            }}
          >
            <div
              style={{
                padding: 30,
                marginLeft: "15vw",
                marginRight: "15vw",
                justifyContent: "space-between",
                display: "flex",
                flex: 1,
              }}
            >
              <Button
                style={{ textTransform: "none" }}
                onClick={(event) =>
                  (window.location.href = "https://cupio.app/")
                }
              >
                <Typography className={classes.footerTextWishes}>
                  Wishes
                </Typography>
              </Button>
              <Button
                style={{ textTransform: "none" }}
                onClick={(event) =>
                  (window.location.href = "https://cupio.app/")
                }
              >
                <Typography className={classes.footerText}>About</Typography>
              </Button>
              <Button
                style={{ textTransform: "none" }}
                onClick={(event) =>
                  (window.location.href = "https://cupio.app/")
                }
              >
                <Typography className={classes.footerText}>
                  Frequently Asked Questions
                </Typography>
              </Button>
              <Button
                style={{ textTransform: "none" }}
                onClick={(event) =>
                  (window.location.href = "https://cupio.app/")
                }
              >
                <Typography className={classes.footerText}>
                  Privacy Policy
                </Typography>
              </Button>
              <Button
                style={{ textTransform: "none" }}
                onClick={(event) =>
                  (window.location.href = "https://cupio.app/")
                }
              >
                <Typography className={classes.footerText}>
                  Terms of Use
                </Typography>
              </Button>
              <Button
                style={{ textTransform: "none" }}
                onClick={(event) =>
                  (window.location.href = "https://cupio.app/")
                }
              >
                <Typography className={classes.footerText}>
                  Cookie Policy
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
