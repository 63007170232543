import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import MobileView from "./MobileView";
import { isMobile } from "react-device-detect";

function AppRouter() {
  if (isMobile) {
    return (
      <Router>
        <Route path="/:id" exact component={MobileView} />
      </Router>
    );
  } else {
    return (
      <Router>
        <Route path="/:id" exact component={App} />
      </Router>
    );
  }
}

export default AppRouter;
