import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import addButton from "./assets/addButton.png";
import subStractButton from "./assets/substractButton.png";
import axios from "axios";
import "./App.css";
import closeReserveModalImg from "./assets/add@3x.png";

const useStyles = makeStyles(() => ({
  cardMedia: {
    width: "10vh",
    height: "10vh",
    marginLeft: "2vh",
    objectFit: "contain",
  },
  cardContentProduct: {
    flex: 3,
    marginLeft: "1vh",
  },
  cardProductTitle: {
    fontFamily: "Lato-Black",
    fontSize: "16px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.4,
    color: "#4c4c4c",
  },
  cardContentStockStatus: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardContentStockStatusTextNotInStock: {
    fontFamily: "Lato-Black",
    fontSize: "14px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#dd003f",
    paddingRight: "10px",
  },
  cardContentStockStatusTextInStock: {
    fontFamily: "Lato-Black",
    fontSize: "14px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#7ed321",
    paddingRight: "10px",
  },
  cardContentPrice: {
    flex: 2,
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  cardContentPriceText: {
    marginLeft: "1vw",
    fontFamily: "Lato-Black",
    fontSize: "18px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 0.69,
    textAlign: "right",
    color: "#4c4c4c",
  },
  cardContentAction: {
    alignItems: "flex-end",
    flex: 2,
    display: "flex",
    flexDirection: "column",
  },
  cardActionButton: {
    borderRadius: "13.5px",
    backgroundColor: "#dd003f",
    shadowColor: "rgba(221, 0, 63, 0.11)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 7,
    shadowOpacity: 1,
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "14px",
    paddingBottom: "14px",
    "&:hover": {
      background: "#dd003f",
    },
    marginLeft: "15px",
  },
  cardActionButtonReserve: {
    borderRadius: "13.5px",
    backgroundColor: "white",
    shadowColor: "rgba(221, 0, 63, 0.11)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 7,
    shadowOpacity: 1,
    paddingLeft: "22px",
    paddingRight: "22px",
    paddingTop: "10px",
    paddingBottom: "10px",
    "&:hover": {
      background: "white",
    },
    borderColor: "#dd003f",
    borderWidth: "2px",
    borderStyle: "solid",
  },
  cardActionButtonShop: {
    borderRadius: "13.5px",
    backgroundColor: "#97a1ae",
    shadowColor: "rgba(221, 0, 63, 0.11)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 7,
    shadowOpacity: 1,
    paddingLeft: "10x",
    paddingRight: "10px",
    paddingTop: "14px",
    paddingBottom: "14px",
    "&:hover": {
      background: "#97a1ae",
    },
    marginLeft: "12px",
  },
  cardActionButtonRemoveReserveation: {
    borderRadius: "13.5px",
    backgroundColor: "white",
    shadowColor: "rgba(221, 0, 63, 0.11)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 7,
    shadowOpacity: 1,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "11px",
    paddingBottom: "11px",
    "&:hover": {
      background: "white",
    },
    borderColor: "#dd003f",
    borderWidth: "2px",
    borderStyle: "solid",
  },
  cardActionButtonText: {
    fontFamily: "Lato-Black",
    fontSize: "10px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 1.54,
    textAlign: "center",
    color: "#ffffff",
  },
  cardActionButtonTextReserve: {
    fontFamily: "Lato-Black",
    fontSize: "11px",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: 1.54,
    textAlign: "center",
    color: "#dd003f",
  },
  card: {
    display: "flex",
    width: "93vw",
    borderRadius: "12px",
    boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)",
    marginTop: "3vh",
    zIndex: 2,
    flexDirection: "column",
  },
  cardCollapse: {
    display: "flex",
    width: "88vw",
    borderRadius: "16.8px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    backgroundColor: "#f6f7f8",
    boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)",
    zIndex: 1,
    flexDirection: "column",
  },
  multiLineEllipsis: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  multiLineEllipsisDescription: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  multiLineEllipsisTitle: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default function ContentCard({ element, index, wishlistID, newData }) {
  const classes = useStyles();

  const [expand, setExpand] = useState(false);
  const [expandIndex, setExpandIndex] = useState(false);
  const [imgwidth, setImgwidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  const [reserve, setReserve] = useState(false);
  const [removeReserve, setRemoveReserve] = useState(false);
  const [dateInput1, setDateInput1] = useState("");
  const [dateInput2, setDateInput2] = useState("");
  const [dateInput3, setDateInput3] = useState("");
  const [dateInput4, setDateInput4] = useState("");
  const [wrongCodeEntered, setWrongCodeEntered] = useState(false);

  const [quantity, setQuantity] = useState(element.quantity);
  const [quantityReserved, setQuantityReserved] = useState(0);
  const [quantitySelected, setQuantitySelected] = useState(1);
  const [initial, setInitial] = useState(true);

  const [shareID, setShareID] = useState(wishlistID.match.params.id);
  const [selectedWishID, setSelectedWishID] = useState(element.wishID._id);

  const [dateFilledOut, setDateFilledOut] = useState(false);

  const [enterBirhDay, setEnterBirthday] = useState(false);
  const date1Ref = useRef(null);
  const date2Ref = useRef(null);
  const date3Ref = useRef(null);
  const date4Ref = useRef(null);
  const [allReserved, setAllReserved] = useState(false);
  const [someReserved, setSomeReserved] = useState(false);
  const [itemsReservedNumber, setItemsReservedNumber] = useState(0);

  const firstShopInStock = element.wishID.shops.filter(
    (shop) => shop.in_stock === "true",
  )[0];

  const checkStatus = (stock_status: string) => {
    if (stock_status === "true") {
      return (
        <Typography className={classes.cardContentStockStatusTextInStock}>
          In stock
        </Typography>
      );
    } else if (stock_status === "false") {
      return (
        <Typography className={classes.cardContentStockStatusTextNotInStock}>
          Out of stock
        </Typography>
      );
    }
  };

  useEffect(() => {
    setQuantityReserved(0);
    setQuantitySelected(1);
    setInitial(true);
    setAllReserved(false);
    setSomeReserved(false);
    let test = 0;

    if (
      element.reserved?.status !== undefined &&
      element.reserved?.status === true
    ) {
      setAllReserved(true);
    } else {
      element.reservedNew?.map(async (it) => {
        if (it.quantity !== undefined) {
          test = test + it.quantity;
        }
        if (
          (element.quantity === 1 && it.user !== undefined) ||
          (element.quantity === 1 && it.code !== null) ||
          element.quantity === test
        ) {
          setAllReserved(true);
        } else if (
          (it.user !== undefined && it.quantity === test) ||
          (it.code !== null && it.quantity === test)
        ) {
          setSomeReserved(true);
        }
      });
      setTimeout(() => {
        setItemsReservedNumber(test);
      }, 1000);
    }
  }, [element]);

  const incrementQuantity = () => {
    setQuantitySelected(quantitySelected + 1);
  };

  const decrementQuantity = () => {
    setQuantitySelected(quantitySelected - 1);
  };

  useEffect(() => {
    if (
      dateInput1.length !== 0 &&
      dateInput2.length !== 0 &&
      dateInput3.length !== 0 &&
      dateInput4.length !== 0
    ) {
      setEnterBirthday(false);
      setDateFilledOut(true);
    } else {
      setDateFilledOut(false);
    }
  }, [dateInput1, dateInput2, dateInput3, dateInput4]);

  useEffect(() => {
    if (reserve === false || removeReserve) {
      setDateInput1("");
      setDateInput2("");
      setDateInput3("");
      setDateInput4("");
      setWrongCodeEntered(false);
      setEnterBirthday(false);
    }
  }, [reserve, removeReserve]);

  useEffect(() => {
    imageSize();
  }, []);

  const reserveWish = async (shareID, wishID, status) => {
    const code = dateInput1 + dateInput2 + dateInput3 + dateInput4;
    const reserveInformation = {
      shareID,
      wishID,
      status,
      code,
      quantity: quantitySelected,
    };

    axios
      .post(
        process.env.REACT_APP_API_URL + "getWebList/reserve",
        reserveInformation,
      )
      .then((response) => {
        if (response.data.code === 200) {
          setQuantityReserved(0);
          setQuantitySelected(1);
          newData();
          setReserve(false);
          setExpand(false);
        }
      });
  };

  const removeReserveWish = async (shareID, wishID) => {
    const code = dateInput1 + dateInput2 + dateInput3 + dateInput4;
    const reserveInformation = { shareID, wishID, code };
    axios
      .post(
        process.env.REACT_APP_API_URL + "getWebList/reserve/remove",
        reserveInformation,
      )
      .then((response) => {
        if (response.data.code === 200) {
          newData();
          setRemoveReserve(false);
        } else if (response.data.code === 500) {
          setWrongCodeEntered(true);
        }
      });
  };

  const imageSize = async () => {
    if (element.wishID.manualAdded === false) {
      try {
        let img = new Image();
        img.onload = function () {
          setImgHeight(img.height);
          setImgwidth(img.width);
        };
        img.src = element.wishID.thumbNail;
      } catch (error) {
        console.log("Error" + error);
      }
    }
  };

  const renderCorrectBackgroundColor = (currBackgroundColor) => {
    if (currBackgroundColor === "white") {
      return "#f6f7f8";
    } else {
      return "white";
    }
  };

  const renderExpand = () => {
    let backgroundcolor = element.wishID.shops.length % 2 ? "white" : "#f6f7f8";

    const shops =
      firstShopInStock === undefined ||
      element.wishID.shops[0].in_stock !== "false"
        ? element.wishID.shops.slice(1)
        : element.wishID.shops;

    if (expand && expandIndex === index) {
      return (
        <Card
          onClick={() => {
            setExpand(false);
            setExpandIndex(index);
          }}
          className={classes.cardCollapse}
        >
          {shops.map((shop, index) => {
            backgroundcolor = renderCorrectBackgroundColor(backgroundcolor);

            return (
              <CardContent
                key={index}
                style={{ backgroundColor: backgroundcolor }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    style={{
                      objectFit: "contain",
                      height: "35px",
                      width: "17vw",
                    }}
                    component="img"
                    image={
                      "https://images.cupio.app/images/logos/" +
                      shop.advertiserId +
                      ".png"
                    }
                    title="Image title"
                  />
                  <Typography
                    style={{
                      fontFamily: "Lato-Black",
                      fontSize: "16px",
                      fontStyle: "normal",
                      letterSpacing: 0.1,
                      marginLeft: "8vw",
                      color: shop.in_stock === "true" ? "#7ed321" : "#dd003f",
                    }}
                  >
                    {shop.newPrice === null
                      ? null
                      : shop.newPrice.toLocaleString("da-DK", {
                          maximumFractionDigits: 0,
                        })}{" "}
                    {shop.visualCurrency}
                  </Typography>

                  <Button
                    onClick={() => window.open(shop.refproductLink, "_blank")}
                    className={classes.cardActionButtonShop}
                  >
                    <Typography className={classes.cardActionButtonText}>
                      GO TO STORE
                    </Typography>
                  </Button>
                </div>
              </CardContent>
            );
          })}
          {allReserved ? null : (
            <CardContent
              style={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                flex: 1,
                flexDirection: "column",
                backgroundColor: "#f6f7f8",
              }}
            >
              <img
                src={"https://cupio.nu/assets/images/6-a.svg"}
                style={{ height: 18, width: 13, objectFit: "contain" }}
                alt=""
              ></img>
              <Typography
                style={{
                  fontFamily: "Lato-Black",
                  fontSize: "11.2px",
                  fontWeight: 900,
                  fontStyle: "normal",
                  letterSpacing: 1.4,
                  color: "#404040",
                }}
              >
                COMPARE RETAILERS
              </Typography>
            </CardContent>
          )}
        </Card>
      );
    } else {
      if (allReserved) {
        return null;
      } else {
        return (
          <Card
            onClick={() => {
              setExpand(true);
              setExpandIndex(index);
            }}
            className={classes.cardCollapse}
          >
            <CardContent
              style={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                flex: 1,
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato-Black",
                  fontSize: "11.2px",
                  fontWeight: 900,
                  fontStyle: "normal",
                  letterSpacing: 1.4,
                  color: "#404040",
                }}
              >
                COMPARE RETAILERS
              </Typography>
              <img
                src={"https://cupio.nu/assets/images/6-a.svg"}
                style={{
                  height: 18,
                  width: 13,
                  objectFit: "contain",
                  transform: "rotate(180deg)",
                }}
                alt=""
              ></img>
            </CardContent>
          </Card>
        );
      }
    }
  };

  const handleDateInputRef = (value, index, keypressed) => {
    //Ensure only numbers are entered.
    var pattern = /^\d+$/;

    if (index === 1 && pattern.test(value)) {
      if (value.length <= 1 && value <= 3) {
        setDateInput1(value);
        if (value.length !== 0) {
          date2Ref.current.focus();
        }
      }
    } else if (index === 2 && pattern.test(value)) {
      if (value.length <= 1) {
        setDateInput2(value);
        if (value.length !== 0) {
          date3Ref.current.focus();
        }
      }
    } else if (index === 3 && value <= 1 && pattern.test(value)) {
      setDateInput3(value);
      if (value.length !== 0) {
        date4Ref.current.focus();
      }
    } else if (index === 4 && pattern.test(value)) {
      if (value.length <= 1) {
        setDateInput4(value);
      }
    }
  };

  const renderManualProductCard = () => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card className={classes.card}>
          <CardContent
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              opacity: allReserved ? 0.25 : 1,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "5vh",
              }}
            >
              {allReserved ? null : (
                <Button
                  onClick={() => {
                    setReserve(true);
                    setSelectedWishID(element.wishID._id);
                  }}
                  className={classes.cardActionButtonReserve}
                  style={{ position: "absolute" }}
                >
                  <Typography className={classes.cardActionButtonTextReserve}>
                    RESERVE
                  </Typography>
                </Button>
              )}
            </div>
            <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <CardMedia
                  style={{
                    objectFit: "contain",
                    height: "100px",
                    width: "100px",
                    aspectRatio: "1",
                    borderRadius: "6px",
                  }}
                  component="img"
                  image={element.wishID.thumbNail}
                  title="Image title"
                />
                <div
                  style={{
                    width: "50vw",
                    marginLeft: "4vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    wordBreak: "break-all",
                  }}
                >
                  <Typography
                    style={{
                      paddingRight: "10px",
                      paddingTop: "4px",
                      fontFamily: "Lato-Regular",
                      fontSize: 14,
                      fontWeight: "normal",
                      fontStyle: "normal",
                      letterSpacing: 0,
                      color: "#808d9f",
                    }}
                  >
                    Added manually
                  </Typography>
                  <Typography
                    className={classes.multiLineEllipsisTitle}
                    style={{
                      paddingRight: "10px",
                      paddingTop: "4px",
                      fontFamily: "Lato-Bold",
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: 1.3,
                      letterSpacing: 0.05,
                      color: "#4c4c4c",
                    }}
                  >
                    {element.wishID.title}
                  </Typography>
                  {element.quantity !== undefined && element.quantity >= 2 ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      {" "}
                      <Typography
                        style={{
                          paddingRight: "20px",
                          paddingLeft: "1.7vw",
                          fontFamily: "lato-regular",
                          fontSize: 16,
                          fontWeight: "bold",
                          fontStyle: "normal",
                          letterSpacing: 0.2,
                          color: "#bdc7d4",
                          marginBottom: 5,
                        }}
                      >
                        {element.quantity} pieces /{" "}
                        <Typography
                          display="inline"
                          style={{
                            fontFamily: "lato-bold",
                            fontSize: 16,
                            fontWeight: "bold",
                            fontStyle: "normal",
                            letterSpacing: 0.2,
                            color: "#bdc7d4",
                          }}
                        >
                          {element.quantity - itemsReservedNumber} left
                        </Typography>
                      </Typography>
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  marginTop: "3vh",
                  width: "80vw",
                  wordBreak: "break-all",
                }}
              >
                <Typography
                  className={classes.multiLineEllipsisDescription}
                  style={{
                    paddingRight: "20px",
                    marginLeft: "1.2vw",
                    fontFamily: "Lato-Regular",
                    fontSize: "15px",
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                >
                  {element.description}
                </Typography>
              </div>
            </div>
          </CardContent>
          {allReserved ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                height: "9vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "4.5vw",
                  flex: 1,
                  alignItems: "center",
                  height: "9vh",
                }}
              >
                <Button
                  onClick={() => {
                    setRemoveReserve(true);
                    setSelectedWishID(element.wishID._id);
                  }}
                  className={classes.cardActionButtonRemoveReserveation}
                >
                  <Typography
                    style={{
                      fontFamily: "Lato-Black",
                      fontSize: 10,
                      fontWeight: 900,
                      fontStyle: "normal",
                      letterSpacing: 1.4,
                      textAlign: "center",
                      color: "#dd003f",
                    }}
                  >
                    REMOVE RESERVATION
                  </Typography>
                </Button>
              </div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#f0f1f3",
                  borderTopLeftRadius: "14px",
                  borderBottomLeftRadius: "0px",
                  display: "flex",
                  paddingLeft: "4vw",
                  paddingRight: "4vw",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "9vh",
                }}
              >
                <div>
                  {window.innerWidth >= 340 ? (
                    <CardMedia
                      style={{
                        objectFit: "contain",
                        height: "30px",
                        width: "30px",
                        paddingRight: "0.5vw",
                      }}
                      component="img"
                      image={
                        "https://cupio.nu/assets/images/giftPackage@3x.png"
                      }
                      title="Image title"
                    />
                  ) : null}
                </div>
                <Typography
                  style={{
                    paddingLeft: window.innerWidth <= 340 ? 0 : "15px",
                    fontFamily: "Lato-Bold",
                    fontSize: 16,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0.1,
                    textAlign: "center",
                    color: "#808d9f",
                  }}
                >
                  Reserved
                </Typography>
              </div>
            </div>
          ) : null}
          {(element.wishID.shops[0].newPrice === null &&
            element.wishID.shops[0].refproductLink === null) ||
          allReserved === true ? null : (
            <CardContent
              style={{
                display: "flex",
                backgroundColor: "#f6f6f6",
                padding: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flex: 3,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 2,
                  }}
                >
                  {element.wishID.shops[0].newPrice === null ||
                  element.wishID.shops[0].newPrice === 0 ? null : (
                    <Typography
                      style={{
                        fontFamily: "Lato-Black",
                        fontSize: "18px",
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 0.1,
                        color: "#4c4c4c",
                      }}
                    >
                      {element.wishID.shops[0].newPrice.toLocaleString(
                        "da-DK",
                        {
                          maximumFractionDigits: 0,
                        },
                      )}{" "}
                      kr.
                    </Typography>
                  )}
                </div>
                {element.wishID.shops[0].refproductLink === null ? (
                  <div style={{ marginLeft: "25px" }}>
                    <Typography
                      style={{
                        fontFamily: "Lato-Regular",
                        fontSize: 14,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        textAlign: "center",
                        color: "rgba(0, 0, 0, 0.40)",
                      }}
                    >
                      Added manually{" "}
                    </Typography>{" "}
                    <Typography
                      style={{
                        marginTop: "-5px",
                        fontFamily: "Lato-Regular",
                        fontSize: 14,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        letterSpacing: 0,
                        textAlign: "center",
                        color: "rgba(0, 0, 0, 0.40)",
                      }}
                    >
                      {" "}
                      without a link{" "}
                    </Typography>
                  </div>
                ) : (
                  <Button
                    onClick={() =>
                      window.open(
                        element.wishID.shops[0].refproductLink,
                        "_blank",
                      )
                    }
                    className={classes.cardActionButton}
                  >
                    <Typography className={classes.cardActionButtonText}>
                      GO TO STORE
                    </Typography>
                  </Button>
                )}
              </div>
            </CardContent>
          )}
        </Card>
      </div>
    );
  };

  const renderAutomaticProductCard = () => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card className={classes.card}>
          <CardContent
            style={{ display: "flex", opacity: allReserved ? 0.25 : 1 }}
          >
            <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "5vh",
                }}
              >
                {allReserved ? null : (
                  <Button
                    onClick={() => {
                      setReserve(true);
                      setSelectedWishID(element.wishID._id);
                    }}
                    className={classes.cardActionButtonReserve}
                    style={{ position: "absolute" }}
                  >
                    <Typography className={classes.cardActionButtonTextReserve}>
                      RESERVE
                    </Typography>
                  </Button>
                )}
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <CardMedia
                  style={{
                    objectFit: "contain",
                    height: "100px",
                    width: "100px",
                    aspectRatio: "1",
                    borderRadius: "6px",
                  }}
                  component="img"
                  image={element.wishID.thumbNail}
                  title="Image title"
                />
                <div
                  style={{
                    marginLeft: "4vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  {firstShopInStock === undefined
                    ? checkStatus(element.wishID.shops[0].in_stock)
                    : checkStatus(firstShopInStock.in_stock)}
                  <Typography
                    className={classes.multiLineEllipsisTitle}
                    style={{
                      paddingRight: "10px",
                      paddingTop: "4px",
                      fontFamily: "Lato-Bold",
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: 1.3,
                      letterSpacing: 0.05,
                      color: "#4c4c4c",
                    }}
                  >
                    {element.wishID.title}
                  </Typography>
                  {element.quantity !== undefined && element.quantity >= 2 ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      {" "}
                      <Typography
                        style={{
                          fontFamily: "lato-regular",
                          fontSize: 16,
                          fontWeight: "bold",
                          fontStyle: "normal",
                          letterSpacing: 0.2,
                          color: "#bdc7d4",
                          marginBottom: 5,
                        }}
                      >
                        {element.quantity} pieces /{" "}
                        <Typography
                          display="inline"
                          style={{
                            fontFamily: "lato-bold",
                            fontSize: 16,
                            fontWeight: "bold",
                            fontStyle: "normal",
                            letterSpacing: 0.2,
                            color: "#bdc7d4",
                          }}
                        >
                          {element.quantity - itemsReservedNumber} left
                        </Typography>
                      </Typography>
                    </div>
                  ) : null}
                </div>
              </div>
              <div style={{ marginTop: "3vh" }}>
                <Typography
                  className={classes.multiLineEllipsisDescription}
                  style={{
                    paddingRight: "20px",
                    marginLeft: "1.2vw",
                    fontFamily: "Lato-Regular",
                    fontSize: "15px",
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                >
                  {element.description}
                </Typography>
              </div>
            </div>
          </CardContent>
          {allReserved ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                height: "9vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "4.5vw",
                  flex: 1,
                  alignItems: "center",
                  height: "9vh",
                }}
              >
                <Button
                  onClick={() => {
                    setRemoveReserve(true);
                    setSelectedWishID(element.wishID._id);
                  }}
                  className={classes.cardActionButtonRemoveReserveation}
                >
                  <Typography
                    style={{
                      fontFamily: "Lato-Black",
                      fontSize: 10,
                      fontWeight: 900,
                      fontStyle: "normal",
                      letterSpacing: 1.4,
                      textAlign: "center",
                      color: "#dd003f",
                    }}
                  >
                    REMOVE RESERVATION
                  </Typography>
                </Button>
              </div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#f0f1f3",
                  borderTopLeftRadius: "14px",
                  borderBottomLeftRadius: "0px",
                  display: "flex",
                  paddingLeft: "4vw",
                  paddingRight: "4vw",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "9vh",
                }}
              >
                <div>
                  {window.innerWidth >= 340 ? (
                    <CardMedia
                      style={{
                        objectFit: "contain",
                        height: "30px",
                        width: "30px",
                        paddingRight: "0.5vw",
                      }}
                      component="img"
                      image={
                        "https://cupio.nu/assets/images/giftPackage@3x.png"
                      }
                      title="Image title"
                    />
                  ) : null}
                </div>
                <Typography
                  style={{
                    paddingLeft: window.innerWidth <= 340 ? 0 : "15px",
                    fontFamily: "Lato-Bold",
                    fontSize: 16,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0.1,
                    textAlign: "center",
                    color: "#808d9f",
                  }}
                >
                  Reserved
                </Typography>
              </div>
            </div>
          ) : null}
          {allReserved ? null : (
            <CardContent
              style={{
                display: "flex",
                backgroundColor: "#f6f6f6",
                padding: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flex: 3,
                }}
              >
                <div style={{ display: "flex", flex: 1, marginLeft: "1.2vw" }}>
                  <CardMedia
                    style={{
                      objectFit: "contain",
                      height: "25px",
                      width: "20vw",
                    }}
                    component="img"
                    image={
                      firstShopInStock === undefined
                        ? "https://images.cupio.app/images/logos/" +
                          element.wishID.shops[0].advertiserId +
                          ".png"
                        : "https://images.cupio.app/images/logos/" +
                          firstShopInStock.advertiserId +
                          ".png"
                    }
                    title="Image title"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flex: 2,
                    marginRight: "1.2vw",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Lato-Black",
                      fontSize: "18px",
                      fontWeight: 900,
                      fontStyle: "normal",
                      letterSpacing: 0.1,
                      color:
                        firstShopInStock === undefined
                          ? element.wishID.shops[0].in_stock === "true"
                            ? "#7ed321"
                            : "#dd003f"
                          : firstShopInStock.in_stock === "true"
                          ? "#7ed321"
                          : "#dd003f",
                    }}
                  >
                    {firstShopInStock === undefined
                      ? element.wishID.shops[0].newPrice.toLocaleString(
                          "da-DK",
                          {
                            maximumFractionDigits: 0,
                          },
                        )
                      : firstShopInStock.newPrice.toLocaleString("da-DK", {
                          maximumFractionDigits: 0,
                        })}{" "}
                    {firstShopInStock === undefined
                      ? element.wishID.shops[0].visualCurrency
                      : firstShopInStock.visualCurrency}
                  </Typography>
                </div>
                <Button
                  onClick={() =>
                    window.open(
                      firstShopInStock === undefined
                        ? element.wishID.shops[0].refproductLink
                        : firstShopInStock.refproductLink,
                      "_blank",
                    )
                  }
                  className={classes.cardActionButton}
                >
                  <Typography className={classes.cardActionButtonText}>
                    GO TO STORE
                  </Typography>
                </Button>
              </div>
            </CardContent>
          )}
        </Card>
        {element.wishID.shops.length >= 2 ? renderExpand() : null}
      </div>
    );
  };

  return (
    <React.Fragment>
      {reserve ? (
        <div
          style={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
            display: "flex",
            backgroundColor: "rgba(52, 52, 52, 0.8)",
            zIndex: 5,
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              position: "absolute",
              padding: "30px",
              display: "flex",
              backgroundColor: "#edf8fd",
              flexDirection: "column",
              height: "85%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: "10px",
                justifyContent: "flex-end",
                marginRight: "-20px",
              }}
            >
              <Button
                style={{
                  top: "-10px",
                  right: "-10px",
                  left: "-10px",
                  bottom: "-10px",
                }}
                onClick={() => {
                  setQuantityReserved(0);
                  setQuantitySelected(1);
                  setReserve(false);
                }}
              >
                {" "}
                <img
                  src={closeReserveModalImg}
                  style={{ height: 58, width: 58 }}
                  alt=""
                ></img>{" "}
              </Button>
            </div>
            <Typography
              style={{
                fontFamily: "lato-black",
                fontSize: 14,
                fontWeight: 900,
                fontStyle: "normal",
                letterSpacing: 2.4,
                textAlign: "center",
                color: "#404040",
                marginTop: "20px",
              }}
            >
              RESERVE WISH
            </Typography>
            {quantity >= 2 ? (
              <div>
                {" "}
                <Typography
                  style={{
                    marginTop: 20,
                    fontFamily: "lato-black",
                    fontSize: 19,
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#4c4c4c",
                    marginBottom: "20px",
                  }}
                >
                  1. Select quantity
                </Typography>
                <Button
                  style={{
                    height: "49px",
                    width: "47px",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    marginRight: "10px",
                    marginBottom: "6px",
                    boxShadow:
                      "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                  }}
                  onClick={() => {
                    if (quantitySelected >= 2) {
                      setInitial(false);
                      decrementQuantity();
                    }
                  }}
                >
                  {" "}
                  <img
                    style={{
                      width: "12px",
                      height: "3px",
                    }}
                    alt=""
                    src={subStractButton}
                  />
                </Button>
                <input
                  disabled={true}
                  type={"number"}
                  value={quantitySelected}
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    height: "47px",
                    width: "64px",
                    borderRadius: "8px",
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                />
                <Button
                  style={{
                    height: "49px",
                    width: "47px",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    marginLeft: "10px",
                    marginBottom: "6px",
                    boxShadow:
                      "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                  }}
                  onClick={() => {
                    if (
                      element.quantity -
                        (quantitySelected + itemsReservedNumber) >=
                      1
                    ) {
                      setInitial(false);
                      incrementQuantity();
                    }
                  }}
                >
                  <img
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                    alt=""
                    src={addButton}
                  />
                </Button>
                <Typography
                  style={{
                    paddingRight: "20px",
                    paddingLeft: "1.7vw",
                    fontFamily: "lato-regular",
                    fontSize: 16,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    textAlign: "center",
                    letterSpacing: 0.2,
                    color: "#bdc7d4",
                    marginBottom: 5,
                    marginTop: 10,
                  }}
                >
                  {quantity} pieces /{" "}
                  <Typography
                    display="inline"
                    style={{
                      fontFamily: "lato-bold",
                      fontSize: 16,
                      fontWeight: "bold",
                      fontStyle: "normal",
                      letterSpacing: 0.2,
                      textAlign: "center",
                      color: "#bdc7d4",
                    }}
                  >
                    {initial
                      ? element.quantity -
                        (quantitySelected + itemsReservedNumber)
                      : element.quantity -
                        (quantitySelected + itemsReservedNumber)}{" "}
                    left
                  </Typography>
                </Typography>
              </div>
            ) : null}
            <Typography
              style={{
                fontFamily: "lato-black",
                fontSize: 19,
                fontWeight: 900,
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "center",
                color: "#4c4c4c",
                marginTop: 20,
              }}
            >
              {quantity >= 2 ? "2." : null} Enter your birthday
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato-Regular",
                marginTop: "20px",
                fontSize: 16,
                fontWeight: "normal",
                fontStyle: "normal",
                letterSpacing: 0,
                color: "#808d9f",
                textAlign: "center",
              }}
            >
              Please enter your birthday as your personal reservation code. You
              will need this code if you later want to remove your reservation.
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4vh",
              }}
            >
              <div style={{ marginRight: "20px", display: "flex" }}>
                <input
                  type={"tel"}
                  pattern="[0-9]"
                  onKeyDown={(key) => {
                    if (key.code === "Backspace") {
                      setDateInput1("");
                    }
                    if (key.code === "ArrowRight") {
                      date2Ref.current.focus();
                    }
                  }}
                  value={dateInput1}
                  onChange={(text) =>
                    handleDateInputRef(text.target.value, 1, null)
                  }
                  ref={date1Ref}
                  placeholder={"D"}
                  maxLength={1}
                  style={{
                    textAlign: "center",
                    height: "53px",
                    width: "43px",
                    borderRadius: 8,
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                />
                <input
                  type={"tel"}
                  pattern="[0-9]"
                  value={dateInput2}
                  onKeyDown={(key) => {
                    if (key.code === "Backspace") {
                      setDateInput2("");
                    }
                    if (key.code === "ArrowLeft") {
                      date1Ref.current.focus();
                    }
                    if (key.code === "ArrowRight") {
                      date3Ref.current.focus();
                    }
                    if (key.code === "Backspace" && dateInput2.length === 0) {
                      date1Ref.current.focus();
                    }
                  }}
                  onChange={(text) =>
                    handleDateInputRef(text.target.value, 2, null)
                  }
                  ref={date2Ref}
                  placeholder={"D"}
                  maxLength={1}
                  style={{
                    textAlign: "center",
                    height: "53px",
                    width: "43px",
                    borderRadius: 8,
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <Typography
                style={{
                  fontFamily: "Lato-Bold",
                  fontSize: 19,
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "center",
                  color: "#808d9f",
                }}
              >
                /
              </Typography>
              <div style={{ marginLeft: "20px", display: "flex" }}>
                <input
                  type={"tel"}
                  pattern="[0-9]"
                  value={dateInput3}
                  onKeyDown={(key) => {
                    if (key.code === "Backspace") {
                      setDateInput3("");
                    }
                    if (key.code === "ArrowLeft") {
                      date2Ref.current.focus();
                    }
                    if (key.code === "ArrowRight") {
                      date4Ref.current.focus();
                    }
                    if (key.code === "Backspace" && dateInput3.length === 0) {
                      date2Ref.current.focus();
                    }
                  }}
                  onChange={(text) =>
                    handleDateInputRef(text.target.value, 3, null)
                  }
                  ref={date3Ref}
                  placeholder={"M"}
                  maxLength={1}
                  style={{
                    textAlign: "center",
                    height: "53px",
                    width: "43px",
                    borderRadius: 8,
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                />
                <input
                  type={"tel"}
                  pattern="[0-9]"
                  value={dateInput4}
                  onKeyDown={(key) => {
                    if (key.code === "Backspace") {
                      setDateInput4("");
                    }
                    if (key.code === "ArrowLeft") {
                      date3Ref.current.focus();
                    }
                    if (key.code === "Backspace" && dateInput4.length === 0) {
                      date3Ref.current.focus();
                    }
                  }}
                  onChange={(text) =>
                    handleDateInputRef(text.target.value, 4, null)
                  }
                  ref={date4Ref}
                  placeholder={"M"}
                  maxLength={1}
                  style={{
                    textAlign: "center",
                    height: "53px",
                    width: "43px",
                    borderRadius: 8,
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    marginLeft: "10px",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                />
              </div>
            </div>
            {enterBirhDay ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Lato-Bold",
                    fontSize: 14,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0.18,
                    textAlign: "center",
                    color: "#dd003f",
                  }}
                >
                  Please enter your birhday
                </Typography>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: enterBirhDay ? "2vh" : "4vh",
                flexDirection: "column",
              }}
            >
              <Button
                style={{
                  paddingLeft: "60px",
                  paddingRight: "60px",
                  paddingTop: "18px",
                  paddingBottom: "18px",
                  backgroundColor: dateFilledOut ? "#dd003f" : "white",
                  borderRadius: "16px",
                  boxShadow:
                    "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                }}
                onClick={() => {
                  if (
                    dateInput1.length === 0 ||
                    dateInput2.length === 0 ||
                    dateInput3.length === 0 ||
                    dateInput4.length === 0
                  ) {
                    setEnterBirthday(true);
                  } else {
                    reserveWish(shareID, selectedWishID, true);
                  }
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Lato-Black",
                    fontSize: 11,
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 1.75,
                    textAlign: "center",
                    color: dateFilledOut ? "white" : "#cfcfcf",
                  }}
                >
                  RESERVE WISH
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {removeReserve ? (
        <div
          style={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
            display: "flex",
            backgroundColor: "rgba(52, 52, 52, 0.8)",
            zIndex: 5,
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              position: "absolute",
              padding: "30px",
              display: "flex",
              backgroundColor: "#edf8fd",
              flexDirection: "column",
              height: "60%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "-20px",
              }}
            >
              <Button
                style={{
                  top: "-10px",
                  right: "-10px",
                  left: "-10px",
                  bottom: "-10px",
                }}
                onClick={() => setRemoveReserve(false)}
              >
                {" "}
                <img
                  src={closeReserveModalImg}
                  style={{ height: 58, width: 58 }}
                  alt=""
                ></img>{" "}
              </Button>
            </div>
            <Typography
              style={{
                fontFamily: "Lato-Black",
                fontSize: 21,
                fontWeight: 900,
                fontStyle: "normal",
                letterSpacing: 0,
                color: "#4c4c4c",
                textAlign: "center",
              }}
            >
              Enter your birthday
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato-Regular",
                marginTop: "20px",
                fontSize: 16,
                fontWeight: "normal",
                fontStyle: "normal",
                letterSpacing: 0,
                color: "#808d9f",
                textAlign: "center",
              }}
            >
              To remove this reservation, please enter the same birthday that
              was used to create it.
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "4vh",
              }}
            >
              <div style={{ marginRight: "20px", display: "flex" }}>
                <input
                  type={"tel"}
                  onKeyDown={(key) => {
                    if (key.code === "Backspace") {
                      setDateInput1("");
                    }
                    if (key.code === "ArrowRight") {
                      date2Ref.current.focus();
                    }
                  }}
                  value={dateInput1}
                  onChange={(text) =>
                    handleDateInputRef(text.target.value, 1, null)
                  }
                  ref={date1Ref}
                  placeholder={"D"}
                  maxLength={1}
                  style={{
                    textAlign: "center",
                    height: "53px",
                    width: "43px",
                    borderRadius: 8,
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                />
                <input
                  type={"tel"}
                  value={dateInput2}
                  onKeyDown={(key) => {
                    if (key.code === "Backspace") {
                      setDateInput2("");
                    }
                    if (key.code === "ArrowLeft") {
                      date1Ref.current.focus();
                    }
                    if (key.code === "ArrowRight") {
                      date3Ref.current.focus();
                    }
                    if (key.code === "Backspace" && dateInput2.length === 0) {
                      date1Ref.current.focus();
                    }
                  }}
                  onChange={(text) =>
                    handleDateInputRef(text.target.value, 2, null)
                  }
                  ref={date2Ref}
                  placeholder={"D"}
                  maxLength={1}
                  style={{
                    textAlign: "center",
                    height: "53px",
                    width: "43px",
                    borderRadius: 8,
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <Typography
                style={{
                  fontFamily: "Lato-Bold",
                  fontSize: 19,
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "center",
                  color: "#808d9f",
                }}
              >
                /
              </Typography>
              <div style={{ marginLeft: "20px", display: "flex" }}>
                <input
                  type={"tel"}
                  value={dateInput3}
                  onKeyDown={(key) => {
                    if (key.code === "Backspace") {
                      setDateInput3("");
                    }
                    if (key.code === "ArrowLeft") {
                      date2Ref.current.focus();
                    }
                    if (key.code === "ArrowRight") {
                      date4Ref.current.focus();
                    }
                    if (key.code === "Backspace" && dateInput3.length === 0) {
                      date2Ref.current.focus();
                    }
                  }}
                  onChange={(text) =>
                    handleDateInputRef(text.target.value, 3, null)
                  }
                  ref={date3Ref}
                  placeholder={"M"}
                  maxLength={1}
                  style={{
                    textAlign: "center",
                    height: "53px",
                    width: "43px",
                    borderRadius: 8,
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                />
                <input
                  type={"tel"}
                  value={dateInput4}
                  onKeyDown={(key) => {
                    if (key.code === "Backspace") {
                      setDateInput4("");
                    }
                    if (key.code === "ArrowLeft") {
                      date3Ref.current.focus();
                    }
                    if (key.code === "Backspace" && dateInput4.length === 0) {
                      date3Ref.current.focus();
                    }
                  }}
                  onChange={(text) =>
                    handleDateInputRef(text.target.value, 4, null)
                  }
                  ref={date4Ref}
                  placeholder={"M"}
                  maxLength={1}
                  style={{
                    textAlign: "center",
                    height: "53px",
                    width: "43px",
                    borderRadius: 8,
                    backgroundColor: "#ffffff",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#e9e9e9",
                    marginLeft: "10px",
                    fontFamily: "Lato-Bold",
                    fontSize: 20,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    color: "#4c4c4c",
                  }}
                />
              </div>
            </div>
            {enterBirhDay ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Lato-Bold",
                    fontSize: 14,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0.18,
                    textAlign: "center",
                    color: "#dd003f",
                  }}
                >
                  Please enter your birhday
                </Typography>
              </div>
            ) : null}
            {wrongCodeEntered ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Lato-Bold",
                    fontSize: 14,
                    fontWeight: "bold",
                    fontStyle: "normal",
                    letterSpacing: 0.18,
                    textAlign: "center",
                    color: "#dd003f",
                  }}
                >
                  Please enter the correct code
                </Typography>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: enterBirhDay ? "2vh" : "4vh",
                flexDirection: "column",
              }}
            >
              <Button
                style={{
                  paddingLeft: "60px",
                  paddingRight: "60px",
                  paddingTop: "18px",
                  paddingBottom: "18px",
                  backgroundColor: dateFilledOut ? "#dd003f" : "white",
                  borderRadius: "16px",
                  boxShadow:
                    "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
                }}
                onClick={() => {
                  if (
                    dateInput1.length === 0 ||
                    dateInput2.length === 0 ||
                    dateInput3.length === 0 ||
                    dateInput4.length === 0
                  ) {
                    setEnterBirthday(true);
                  } else {
                    removeReserveWish(shareID, selectedWishID);
                  }
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Lato-Black",
                    fontSize: 11,
                    fontWeight: 900,
                    fontStyle: "normal",
                    letterSpacing: 1.75,
                    textAlign: "center",
                    color: dateFilledOut ? "white" : "#cfcfcf",
                  }}
                >
                  REMOVE RESERVATION
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      ) : null}
      {element.wishID.manualAdded
        ? renderManualProductCard()
        : renderAutomaticProductCard()}
    </React.Fragment>
  );
}
