import React, { useState, useEffect, useCallback } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import "./App.css";
import MobileCard from "./MobileCard";
import zIndex from "@material-ui/core/styles/zIndex";
import Helmet from "react-helmet";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "10vh",
    width: "100%",
  },
  root: {
    boxShadow: "0 3px 12px 0 rgba(50, 104, 141, 0.08)",
    overflow: "hidden",
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  profilePicture: {
    boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)",
    borderStyle: "solid",
    borderWidth: 6,
    borderColor: "#ffffff",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    objectFit: "contain",
    marginTop: "12vh",
  },
  profilePictureOverlay: {
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    objectFit: "contain",
    filter: "brightness(50%)",
  },
  footerTextWishes: {
    fontFamily: "Lato-Bold",
    fontSize: "13px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#dd003f",
  },
  footerText: {
    fontFamily: "Lato-Bold",
    fontSize: "13px",
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#bdc7d4",
  },
}));

export default function Album(id) {
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newData, setNewData] = useState(false);
  const [osVersion, setosVersion] = useState("");
  const [collaboratorsData, setCollaboratorsData] = useState(null);

  useEffect(() => {
    getWishList();
  }, []);

  useEffect(() => {
    if (
      navigator.userAgent.includes("iP") ||
      navigator.userAgent.includes("Mac")
    ) {
      setosVersion("iPhone");
    } else {
      setosVersion("android");
    }
  }, []);

  const getWishList = async () => {
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "getWebList/" + id.match.params.id)
        .then((res) => {
          res.data.wishData.wishes.sort((a, b) => {
            // equal items sort equally
            if (a.wishID.shops[0].newPrice === b.wishID.shops[0].newPrice) {
              return 0;
            }

            // nulls sort after anything else
            if (
              a.wishID.shops[0].newPrice === 0 ||
              a.wishID.shops[0].newPrice === null ||
              a.wishID.shops[0].newPrice === undefined
            ) {
              return 1;
            }
            if (
              b.wishID.shops[0].newPrice === 0 ||
              b.wishID.shops[0].newPrice === null ||
              b.wishID.shops[0].newPrice === undefined
            ) {
              return -1;
            }
            return a < b ? -1 : 1;
          });
          setData(res.data.wishData);
          setCollaboratorsData(res.data.collaborators);
          setCollaboratorsData((collaboratorsData) => [
            ...collaboratorsData,
            res.data.personInformation,
          ]);
          setUserData(res.data.personInformation);
        })
        .finally(() => setLoading(false))
        .catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  };

  const loadDataFromAPI = useCallback(() => {
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "getWebList/" + id.match.params.id)
        .then((res) => {
          res.data.wishData.wishes.sort((a, b) => {
            // equal items sort equally
            if (a.wishID.shops[0].newPrice === b.wishID.shops[0].newPrice) {
              return 0;
            }

            // nulls sort after anything else
            if (
              a.wishID.shops[0].newPrice === 0 ||
              a.wishID.shops[0].newPrice === null ||
              a.wishID.shops[0].newPrice === undefined
            ) {
              return 1;
            }
            if (
              b.wishID.shops[0].newPrice === 0 ||
              b.wishID.shops[0].newPrice === null ||
              b.wishID.shops[0].newPrice === undefined
            ) {
              return -1;
            }
            return a < b ? -1 : 1;
          });
          setData(res.data.wishData);
          setCollaboratorsData(res.data.collaborators);
          setCollaboratorsData((collaboratorsData) => [
            ...collaboratorsData,
            res.data.personInformation,
          ]);
          setUserData(res.data.personInformation);
        })
        .finally(() => setLoading(false))
        .catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <React.Fragment>
      {loading ? null : (
        <div>
          <>
            <Helmet>
              <meta
                name="title"
                content={data.wishListName.toUpperCase()}
              ></meta>
              <meta
                name="description"
                content={
                  userData.name.split(" "[0]) +
                  "shared the list " +
                  data.wishListName.toUpperCase() +
                  "with you."
                }
              ></meta>
              <meta
                name="og:image"
                content={
                  "https://assets-global.website-files.com/63f3764fa511aa60d0ad9849/642bd14d5203807da729b0e0_Cupio_logo.jpg"
                }
              ></meta>
            </Helmet>
          </>
          <div className={classes.root}>
            <AppBar
              style={{ boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)" }}
              position="fixed"
              color="inherit"
            >
              <Toolbar>
                <div
                  style={{
                    flexDirection: "row",
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 0.3, display: "flex" }}>
                    <img
                      src={"https://cupio.nu/assets/images/cupio-logo.svg"}
                      style={{ height: "40px", width: "65px" }}
                      alt="logo"
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.4,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      style={{
                        overflow: "hidden",
                        whiteSpace: "pre-wrap",
                        textAlign: "center",
                        fontFamily: "Lato-Black",
                        fontSize: "16px",
                        fontWeight: 900,
                        fontStyle: "normal",
                        letterSpacing: 2.93,
                        color: "#404040",
                        wordBreak: "break-word",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        width: "55%",
                      }}
                      className={classes.fabButton}
                    >
                      {data.wishListName.toUpperCase()}
                    </Typography>
                  </div>
                  <div style={{ flex: 0.3, display: "flex" }} />
                </div>
              </Toolbar>
            </AppBar>
          </div>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            style={{ minHeight: "100vh", backgroundColor: "#edf8fd" }}
          >
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {collaboratorsData.slice(0, 3).map((collaborator, index) => {
                if (index === 2 && collaboratorsData.length > 3) {
                  return (
                    <div
                      style={{
                        marginLeft: index === 0 ? 0 : -50,
                        marginBottom: 15,
                        marginTop: "13vh",
                      }}
                    >
                      {collaborator.onlinePicture === null ? (
                        <div
                          style={{
                            boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)",
                            borderStyle: "solid",
                            borderWidth: 6,
                            borderColor: "#ffffff",
                            borderRadius: "50%",
                            width: "120px",
                            height: "120px",
                            objectFit: "contain",
                          }}
                        >
                          <Typography
                            style={{
                              position: "absolute",
                              marginTop: "30px",
                              zIndex: 2,
                              marginLeft: "38px",
                              fontFamily: "Lato-Black",
                              fontSize: "35px",
                              fontWeight: "bold",
                              fontStyle: "normal",
                              letterSpacing: 0.28,
                              color: "white",
                            }}
                          >
                            + {collaboratorsData.length - (index + 1)}
                          </Typography>
                          <img
                            className={classes.profilePictureOverlay}
                            src={`data:image/jpeg;base64,${collaborator.offlinePicture}`}
                            alt="user"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            boxShadow: "0 3px 8px 1px rgba(51, 58, 96, 0.08)",
                            borderStyle: "solid",
                            borderWidth: 6,
                            borderColor: "#ffffff",
                            borderRadius: "50%",
                            width: "120px",
                            height: "120px",
                            objectFit: "contain",
                          }}
                        >
                          <Typography
                            style={{
                              position: "absolute",
                              marginTop: "30px",
                              zIndex: 2,
                              marginLeft: "38px",
                              fontFamily: "Lato-Black",
                              fontSize: "35px",
                              fontWeight: "bold",
                              fontStyle: "normal",
                              letterSpacing: 0.28,
                              color: "white",
                            }}
                          >
                            + {collaboratorsData.length - (index + 1)}
                          </Typography>
                          <img
                            className={classes.profilePictureOverlay}
                            src={collaborator.onlinePicture}
                            alt="user"
                          />
                        </div>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div style={{ marginLeft: index === 0 ? 0 : -50 }}>
                      {collaborator.onlinePicture === null ? (
                        <img
                          className={classes.profilePicture}
                          src={`data:image/jpeg;base64,${collaborator.offlinePicture}`}
                          alt="user"
                        ></img>
                      ) : (
                        <img
                          className={classes.profilePicture}
                          src={collaborator.onlinePicture}
                          alt="user"
                        ></img>
                      )}
                    </div>
                  );
                }
              })}
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              style={{ marginTop: 16 }}
            >
              {collaboratorsData.map((collaborator, index) => {
                if (index === collaboratorsData?.length - 1) {
                  return (
                    <Typography
                      noWrap
                      style={{
                        marginLeft: index === 0 ? 15 : 0,
                        marginRight:
                          index + 1 === collaboratorsData.length ? 15 : 0,
                        fontFamily: "Lato-Bold",
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.28,
                        textAlign: "center",
                        color: "#4c4c4c",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {collaborator.name.split(" ")[0]}
                    </Typography>
                  );
                } else if (index === collaboratorsData?.length - 2) {
                  return (
                    <Typography
                      noWrap
                      style={{
                        marginLeft: index === 0 ? 15 : 0,
                        marginRight:
                          index + 1 === collaboratorsData.length ? 15 : 0,
                        fontFamily: "Lato-Bold",
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.28,
                        textAlign: "center",
                        color: "#4c4c4c",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {collaborator.name.split(" ")[0]} & &#8202;
                    </Typography>
                  );
                } else {
                  return (
                    <Typography
                      noWrap
                      style={{
                        marginLeft: index === 0 ? 15 : 0,
                        marginRight:
                          index + 1 === collaboratorsData.length ? 15 : 0,
                        fontFamily: "Lato-Bold",
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        letterSpacing: 0.28,
                        textAlign: "center",
                        color: "#4c4c4c",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {collaborator.name.split(" ")[0]}, &#8202;
                    </Typography>
                  );
                }
              })}
            </Grid>

            {data.wishes.length === 1 ? (
              <Typography
                style={{
                  marginTop: "1vh",
                  color: "#97a1ae",
                  fontFamily: "Lato-Bold",
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0.46,
                  textAlign: "center",
                }}
              >
                {data.wishes.length} item{" "}
              </Typography>
            ) : (
              <Typography
                style={{
                  marginTop: "1vh",
                  color: "#97a1ae",
                  fontFamily: "Lato-Bold",
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0.46,
                  textAlign: "center",
                }}
              >
                {data.wishes.length} items{" "}
              </Typography>
            )}

            <Button
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "15px",
                paddingBottom: "15px",
                marginTop: "15px",
                backgroundColor: "white",
                borderRadius: "13px",
                boxShadow:
                  "0 2px 8px 2px rgba(38, 98, 138, 0.04), 0 1px 2px 1px rgba(0, 0, 0, 0.04)",
              }}
              onClick={() =>
                (document.location =
                  "cupioapp://home/wishID/" + id.match.params.id)
              }
            >
              <Typography
                style={{
                  fontFamily: "lato-black",
                  fontSize: 10,
                  fontWeight: 900,
                  fontStyle: "normal",
                  letterSpacing: 1.54,
                  textAlign: "center",
                  color: "#808d9f",
                }}
              >
                FOLLOW LIST
              </Typography>
            </Button>

            {data.wishes.map((el, index) => (
              <MobileCard
                element={el}
                index={index}
                wishlistID={id}
                newData={loadDataFromAPI}
              />
            ))}

            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "5vh",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato-Bold",
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  letterSpacing: 0.05,
                  textAlign: "center",
                  color: "#808d9f",
                }}
              >
                Like what you see?
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato-Bold",
                  fontSize: "16px",
                  letterSpacing: 0.05,
                  fontWeight: "bold",
                  fontStyle: "normal",
                  textAlign: "center",
                  color: "#808d9f",
                }}
              >
                Create your own wish list with cupio 🥳
              </Typography>
            </div>
            <div style={{ marginTop: "4vh" }}>
              <img
                onClick={() =>
                  (window.location.href =
                    "https://apps.apple.com/dk/app/cupio/id1587188542?l=da")
                }
                style={{
                  width: "120px",
                  height: "45px",
                }}
                alt=""
                src={"https://cupio.nu/assets/images/AppStore.svg"}
              />
              <img
                style={{
                  marginLeft: "25px",
                  width: "120px",
                  height: "45px",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://play.google.com/store/apps/details?id=com.cupio.cupio&fbclid=IwAR2WYsRsE7nPmbngxresCOTTBmzqscqBk-UFjwq1r1uJ4GuUQX4bm-VBL04&pli=1")
                }
                alt=""
                src={"https://cupio.nu/assets/images/GooglePlay.svg"}
              />
            </div>
            <div
              style={{
                marginTop: "4vh",
                paddingLeft: "5vw",
                paddingRight: "5vw",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato-Regular",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontStyle: "normal",
                  letterSpacing: 0.05,
                  textAlign: "center",
                  color: "#97a1ae",
                }}
              >
                Unfortunately, we cannot guarantee that all retailers are
                included in the comparison. Additionally, we are not responsible
                for any changes in price or stock status.{"\n"}
                {"\n"}
                We solely rank based on price and do not favor any retailers
                over others. Our links are affiliate links - but rest assured,
                it does not affect the price of your items!
              </Typography>
            </div>

            <div
              style={{
                marginTop: "10%",
                backgroundColor: "white",
                width: "100%",
                justifyContent: "space-around",
                display: "flex",
                alignItems: "center",
                boxShadow: "0 -5px 20px 0 rgba(43, 74, 95, 0.08)",
              }}
            >
              <div
                style={{
                  padding: "25px",
                  marginLeft: "2vw",
                  marginRight: "2vw",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  display: "flex",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "5px",
                  }}
                >
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() => (window.location.href = "http://cupio.app/")}
                  >
                    <Typography className={classes.footerTextWishes}>
                      Wishes
                    </Typography>
                  </Button>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() => (window.location.href = "http://cupio.app/")}
                  >
                    <Typography className={classes.footerText}>
                      About
                    </Typography>
                  </Button>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() => (window.location.href = "http://cupio.app/")}
                  >
                    <Typography className={classes.footerText}>FAQ</Typography>
                  </Button>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.cupio.app/terms/terms-of-use")
                    }
                  >
                    <Typography className={classes.footerText}>
                      Terms of Use
                    </Typography>
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "8px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.cupio.app/terms/privacy-policy")
                    }
                  >
                    <Typography className={classes.footerText}>
                      Privacy Policy
                    </Typography>
                  </Button>
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={() =>
                      (window.location.href =
                        "https://www.cupio.app/terms/cookie-policy")
                    }
                  >
                    <Typography className={classes.footerText}>
                      Cookie Policy
                    </Typography>
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}
